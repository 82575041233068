import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import ProfitAndLossTab from "./ProfitAndLossTab/ProfitandLossTab";
import CashFlowTab from "./CashFlowTab/CashFlowTab";
import CustomReportTab from "./CustomReportTab/CustomReportTab";

const ProfitandLoss = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTabStyle = (selected) => {
    return selected
      ? {
          border: "2px solid #1976d2",
          color: "white",
          backgroundColor: "#1976d2",
          fontSize: "13px",
        }
      : { border: "2px solid #1976d2", color: "#1976d2", fontSize: "13px" };
  };

  return (
    <div className="p-20">
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            textColor="primary"
            variant="contained"
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            <Tab
              label="Profit & Loss"
              value="1"
              style={{
                ...getTabStyle(value === "1"),
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            />
            <Tab
              label="Cash Flow"
              value="2"
              style={{
                ...getTabStyle(value === "2"),
                borderLeft: "0.5px solid #1976d2",
                borderRight: "0.5px solid #1976d2",
              }}
            />
            <Tab
              label="Custom Report"
              value="3"
              style={{
                ...getTabStyle(value === "3"),
                borderLeft: "0.5px solid #1976d2",
                borderRight: "2px solid #1976d2",
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: 0, margin: 0, my: 2 }}>
          <ProfitAndLossTab />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0, margin: 0 }}>
          <CashFlowTab />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: 0, margin: 0, my: 1 }}>
          <CustomReportTab />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default ProfitandLoss;
