import { useEffect, useState } from "react";
import Revenue from "./Revenue/Revenue";
import Expense from "./Expense/Expense";
import People from "./People/People";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getSpreadSheet,
  resetCurrentSpreadSheet,
  syncPriorPeriod,
  syncSpreadsheet,
  updateSpreadsheetKey,
  updateSpreadsheet,
  setRefreshing,
  getActiveAccounts,
  resetActiveIds,
  setSpreadsheetDataReference,
} from "../../../redux/SpreadSheet/SpreadSheetSlice";
import Loading from "../../../components/LoadingIndicator/Loading";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  Add,
  Close,
  Info,
  ReplayOutlined,
  Settings,
  Upload,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { getRevenueMapping } from "../../../redux/Revenue/RevenueSlice";
import { getExpenseMapping } from "../../../redux/Expense/ExpenseSlice";
import _ from "lodash";
import {
  METRIC_SETTING_COLUMNS,
  METRIC_SETTING_HEADERS,
  MAPPING_COLUMNS,
  MAPPING_COLUMNS_HEADERS,
  DEFAULT_COLUMNS,
  DEFAULT_PEOPLE_COLUMNS,
  PRIOR_PERRIOD_KEYS,
  DEFAULT_KEYS,
  DEFAULT_CELL_VALUES,
  DEFAULT_PEOPLE_CELL_VALUES,
  ROW_TYPE,
  style,
  PEOPLE_METRIC_SETTING_COLUMNS,
  PEOPLE_METRIC_SETTING_HEADERS,
} from "./defaultValues";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import {
  calculateMonthlySalary,
  handleReferenceUpdate,
  monthName,
  peoplePayrollCalculation,
  tabType,
} from "./helper";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import {
  handleValueCalculationsForecastIncluded,
  handleSumRowsHelper,
  // handleMonthlySalaryRowsHelper,
} from "./helper";
import { Tooltip } from "@material-ui/core";
import Cash from "./Cash/Cash";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import { getEntity } from "../../../redux/ClientSpace/ClientSpaceSlice";
import "./SpreadSheet.css";
const { RangePicker } = DatePicker;

const StyledTab = withStyles({
  root: {
    minHeight: "32px !important",
    padding: "0px !important",
    textTransform: "none !important",
  },
})(Tab);

export const SideNavStyledTab = withStyles({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #e8e8e8",
    fontSize: "15px",
  },
})(Tab);

const getTabStyle = (selected) => {
  return selected
    ? {
        border: "2px solid #1976d2",
        color: "white",
        backgroundColor: "#1976d2",
      }
    : { border: "2px solid #1976d2", color: "#1976d2" };
};

export const getRowStyle = (type, condition = true) => {
  switch (type) {
    case ROW_TYPE.TOTAL_ROW:
      return condition
        ? { fontWeight: 900, fontSize: "16px", background: "#FFFFFF" }
        : {
            fontWeight: 800,
            fontSize: "14px",
            background: "#DFE4F0",
          };
    case ROW_TYPE.SUB_TOTAL_ROW:
      return { fontWeight: 600, fontSize: "15px", background: "#FFFFFF" };
    case ROW_TYPE.TITLE_ROW:
      return {
        fontWeight: 800,
        fontSize: "15px",
        background: "#6D81B0",
        color: "#FFFFFF",
      };
    case ROW_TYPE.CUSTOM_METRIC:
      return {
        fontWeight: 800,
        fontSize: "14px",
        background: "#DFE4F0",
        // border: {
        //   top: { color: "#000000", style: "solid", width: "1px" },
        //   bottom: { color: "#000000", style: "solid", width: "1px" },
        // },
      };
    case ROW_TYPE.ACCOUNT_GROUP:
      return condition
        ? { fontWeight: 700, fontSize: "14px", background: "#EFF2F8 " }
        : { fontWeight: 500, fontSize: "14px", background: "#EFF2F8 " };
    case ROW_TYPE.ACCOUNT:
      return condition
        ? { fontWeight: 700, fontSize: "14px", background: "#FFFFFF " }
        : { fontWeight: 500, fontSize: "14px", background: "#FFFFFF " };
    case ROW_TYPE.METRIC:
      return condition
        ? {
            fontWeight: 400,
            fontSize: "14px",
            background: "#FFFFFF ",
            color: "#094498",
          }
        : {
            fontWeight: 400,
            fontSize: "14px",
            background: "#FFFFFF ",
            color: "#000000",
          };
    default:
      return "";
  }
};
export const getRowClass = (type, condition = false) => {
  if (type === ROW_TYPE.METRIC) {
    return condition ? "text-italics" : "";
  } else {
    return "";
  }
};

export default function SpreadSheet() {
  const [value, setValue] = useState(null);
  const [spreadSheet, setSpreadSheet] = useState({});
  const { entityId, spreadSheetId, accountingFirmId } = useParams();
  const [priorPeriodOpen, setPriorPeriodOpen] = useState(false);
  const [metricSettingsOpen, setMetricSettingsOpen] = useState(false);
  const [settingsOpen, setSettingOpen] = useState(false);
  const [defaultSettings, setDefaultSettings] = useState({
    offsetValue: 0,
    durationType: "month",
    durationStart: dayjs().toISOString()?.slice(0, 7),
    durationEnd: dayjs().toISOString()?.slice(0, 7),
    viewStart: null,
    viewEnd: null,
  });
  const [durationAddModal, setDurationAddModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState(defaultSettings);
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
  const [dataUploadOpen, setDataUploadOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [possibleSyncItems, setPossibleSyncItems] = useState({
    revenue: [],
    expense: [],
  });
  const [selectedSyncItems, setSelectedSyncItems] = useState({
    revenue: [],
    expense: [],
  });
  const [defaultSelectedSyncItems, setDefaultSelectedSyncItems] = useState({
    revenue: [],
    expense: [],
  });
  const [showTerminated, setShowTerminated] = useState(false);
  const [paymentTypeChangeConfirm, setPaymentTypeConfirm] = useState(false);
  const { currentEntity, isFetching: isFetchingEntity } = useSelector(
    (state) => state.clientSpaces
  );
  const [tabSwitch, setTabSwitch] = useState(false);
  const [updatedFinancialSettingModal, setUpdatedFinancialSettingModal] =
    useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const [isCashorAccrualType, setIsCashorAccrualType] = useState(true);
  const {
    spreadsheet: data,
    isFetching,
    refreshData,
    isRefreshing,
    activeIds,
    coaNames,
  } = useSelector((state) => state.spreadsheet);
  useEffect(() => {
    dispatch(
      getEntity({
        entityId,
        accountingFirmId,
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(getSpreadSheet({ entityId, spreadSheetId, accountingFirmId }));
    dispatch(getRevenueMapping({ entityId, accountingFirmId }));
    dispatch(getExpenseMapping({ entityId, accountingFirmId }));
    // eslint-disable-next-line
  }, [spreadSheetId]);
  useEffect(() => {
    if (data && refreshData) {
      setSpreadSheet(data);
      setValue(data?.activeTab || 0);
      if (!data?.durationAdded) {
        setSettingOpen(true);
      }

      setIsCashorAccrualType(data.people.paymentType === "Accrual");
      if (data?.updatedFinancialSetting && data?.durationAdded) {
        setUpdatedFinancialSettingModal(true);
      }

      let range = {
        offsetValue: data?.offsetValue || defaultSettings.offsetValue,
        durationType: data?.durationType || defaultSettings.durationType,
        durationStart: data?.durationStart || defaultSettings.durationStart,
        durationEnd: data?.durationEnd || defaultSettings.durationEnd,
        viewStart: data?.viewStart || defaultSettings.viewStart,
        viewEnd: data?.viewEnd || defaultSettings.viewEnd,
      };
      setDefaultSettings(range);
      setDateRange(range);
    }
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    return () => {
      dispatch(resetCurrentSpreadSheet());
    };
  }, [dispatch]);

  const getType = (value) => {
    switch (value) {
      case 0:
        return "revenue";
      case 1:
        return "expense";
      case 2:
        return "people";
      case 3:
        return "cash";
      default:
        return "revenue";
    }
  };

  const handleMetricSetting = () => {
    handleClose();
    let type = getType(value);
    let { columns, headers: headerRow } = data?.[type];
    let metricSetting = data?.[type]?.metricSettingsOpen;
    let clone = _.cloneDeep(columns);
    let headersClone = _.cloneDeep(headerRow);
    if (
      columns
        .map((column) => column.columnId)
        .indexOf(type === "people" ? "weeklyHours" : "valueType") > -1
    ) {
      clone.splice(3, type === "people" ? 6 : 4);
      headersClone.cells.splice(3, type === "people" ? 6 : 4);
    } else {
      clone.splice(
        3,
        0,
        ...(type === "people"
          ? PEOPLE_METRIC_SETTING_COLUMNS
          : METRIC_SETTING_COLUMNS)
      );
      headersClone.cells.splice(
        3,
        0,
        ...(type === "people"
          ? PEOPLE_METRIC_SETTING_HEADERS
          : METRIC_SETTING_HEADERS)
      );
    }
    dispatch(setRefreshing());
    dispatch(
      updateSpreadsheetKey({
        payload: {
          type: type,
          value: {
            ...data?.[type],
            columns: clone,
            headers: headersClone,
            metricSettingsOpen: !metricSetting,
          },
          entityId,
          refreshData: true,
        },
        spreadSheetId,
        accountingFirmId,
      })
    );
  };

  const handleUpdate = (
    value,
    refreshData = true,
    activeTabChange = false,
    tabValue,
    tabCalculationType
  ) => {
    let { spreadsheet } = activeTabChange
      ? spreadSheet
      : handleReferenceUpdate(
          value,
          value.people.rows,
          "people",
          dateRange,
          dispatch,
          setSpreadsheetDataReference,
          currentEntity,
          tabCalculationType
        );
    if (refreshData) {
      dispatch(setRefreshing());
    }
    dispatch(
      updateSpreadsheet({
        payload: {
          value: {
            ...spreadsheet,
            activeTab:
              activeTabChange || tabValue
                ? parseInt(tabValue)
                : spreadSheet?.activeTab || 0,
          },
          refreshData,
          entityId,
        },
        spreadSheetId,
        accountingFirmId,
      })
    );
  };

  const handlePriorPeriod = () => {
    handleClose();
    let type = getType(value);
    if (type !== "people") {
      let { columns, headers: headerRow } = data[type];
      let priorPeriod = data?.[type]?.priorPeriodOpen;
      let clone = _.cloneDeep(columns);
      let headersClone = _.cloneDeep(headerRow);

      if (
        columns.map((column) => column.columnId).indexOf("financialYearData") >
        -1
      ) {
        clone.splice(
          columns.map((column) => column.columnId).indexOf("valueType") > -1
            ? 7
            : 3,
          4
        );
        headersClone.cells.splice(
          columns.map((column) => column.columnId).indexOf("valueType") > -1
            ? 7
            : 3,
          4
        );
      } else {
        clone.splice(
          columns.map((column) => column.columnId).indexOf("valueType") > -1
            ? 7
            : 3,
          0,
          ...MAPPING_COLUMNS
        );
        headersClone.cells.splice(
          columns.map((column) => column.columnId).indexOf("valueType") > -1
            ? 7
            : 3,
          0,
          ...MAPPING_COLUMNS_HEADERS
        );
      }
      dispatch(setRefreshing());

      dispatch(
        updateSpreadsheetKey({
          payload: {
            type: type,
            value: {
              ...data?.[type],
              columns: clone,
              headers: headersClone,
              priorPeriodOpen: !priorPeriod,
            },
            entityId,
            refreshData: true,
          },
          spreadSheetId,
          accountingFirmId,
        })
      );
    }
  };

  //handle terminate in people settings
  const handleTerminated = () => {
    handleClose();
    setShowTerminated(!showTerminated);
  };

  useEffect(() => {
    if (data) {
      if (value === 0) {
        setPriorPeriodOpen(data.revenue.priorPeriodOpen);
        setMetricSettingsOpen(data.revenue.metricSettingsOpen);
      }
      if (value === 1) {
        setPriorPeriodOpen(data.expense.priorPeriodOpen);
        setMetricSettingsOpen(data.expense.metricSettingsOpen);
      }
      if (value === 2) {
        setPriorPeriodOpen(data.people.priorPeriodOpen);
        setMetricSettingsOpen(data.people.metricSettingsOpen);
      }
    }
  }, [data, value]);

  //handle duration filter settings
  const handleSettings = () => {
    setSettingOpen(!settingsOpen);
    handleClose();
  };

  //handle tab select
  const setSelected = (val) => {
    setValue(val);
    setTabSwitch(true);
    handleUpdate(spreadSheet, false, true, val, tabType.none);
  };

  //handle sync with coa
  const handleSyncSpreadsheet = () => {
    dispatch(setRefreshing());
    handleClose();
    dispatch(getActiveAccounts({ entityId, accountingFirmId }));
  };

  useEffect(() => {
    if (activeIds.length) {
      let revenueUnsynced = [...(spreadSheet?.revenue?.rows || [])].filter(
        (row) => !row?.xeroSynced && row.manualAccountMetric
      );
      let expenseUnsynced = [...(spreadSheet?.expense?.rows || [])].filter(
        (row) => !row?.xeroSynced && row.manualAccountMetric
      );

      let activeNames = activeIds.map((val) => val.name.trim());
      let possibleSyncItemsRevenue = revenueUnsynced.filter((row) =>
        activeNames.includes(row.metricName.trim())
      );
      let possibleSyncItemsExpense = expenseUnsynced.filter((row) =>
        activeNames.includes(row.metricName.trim())
      );
      if (
        possibleSyncItemsRevenue.length === 0 &&
        possibleSyncItemsExpense.length === 0
      ) {
        dispatch(
          syncSpreadsheet({
            spreadSheetId,
            unSyncedRows: null,
            accountingFirmId,
          })
        );
      } else {
        setPossibleSyncItems({
          revenue: possibleSyncItemsRevenue,
          expense: possibleSyncItemsExpense,
        });
        let requiredRevenueIds = [];
        let requiredExpenseIds = [];
        possibleSyncItemsRevenue.forEach((item) => {
          if (coaNames?.expense.includes(item.metricName)) {
            requiredRevenueIds.push({ id: item.id, name: item.metricName });
          }
        });
        possibleSyncItemsExpense.forEach((item) => {
          if (coaNames?.revenue.includes(item.metricName)) {
            requiredExpenseIds.push({ id: item.id, name: item.metricName });
          }
        });
        setDefaultSelectedSyncItems({
          revenue: requiredRevenueIds,
          expense: requiredExpenseIds,
        });
      }
      dispatch(resetActiveIds());
    }
    // eslint-disable-next-line
  }, [activeIds]);

  useEffect(() => {
    if (
      possibleSyncItems.revenue.length !== 0 ||
      possibleSyncItems.expense.length !== 0
    )
      setModalOpen(true);
  }, [possibleSyncItems]);

  const handleSyncPriorPeriod = () => {
    dispatch(setRefreshing());
    handleClose();
    dispatch(syncPriorPeriod({ spreadSheetId, accountingFirmId }));
  };

  //duration filter reset
  const handleResetSettings = () => {
    setDateRange(defaultSettings);
  };

  //update rows columns and headers of spreadsheet by adding dates from duration filter
  //calculate logic for xero and calculation rows
  const getUpdatedRowsColumnsHeader = (type, range) => {
    let value = {};
    let data = range;

    if (type === "revenue") {
      value = _.cloneDeep(spreadSheet?.revenue);
    } else if (type === "expense") {
      value = _.cloneDeep(spreadSheet?.expense);
    } else {
      value = _.cloneDeep(spreadSheet?.people);
    }

    //columns added in spreadshhets selected from durtartion filter
    const additionalColumns = range.map((value) => {
      return {
        columnId: value.month.toLowerCase() + value.year,
        width: 200,
        resizable: true,
      };
    });
    let possibleDefaultColumns =
      type === "people"
        ? [...DEFAULT_PEOPLE_COLUMNS]
        : [...DEFAULT_COLUMNS, ...PRIOR_PERRIOD_KEYS];
    let filteredColumns = value.columns.filter((column) =>
      possibleDefaultColumns.includes(column.columnId)
    );

    const headerAdditionalData = data.map((value) => {
      return {
        text: value.month + " " + value.year,
        type: "header",
      };
    });

    data = data.map((date) => date.month.toLowerCase() + date.year);
    let payrollRows =
      type === "people"
        ? generatePayrollInformation(additionalColumns, value.rows)
        : [];
    let clone = _.cloneDeep(value.rows);
    value?.rows.forEach((row, index) => {
      Object.keys(row).forEach((key) => {
        //if the default keys does not match with the keys of rows then if part
        if (!DEFAULT_KEYS.includes(key)) {
          if (!data.includes(key)) {
            clone[index][key]["disabled"] = true;
          }
        } else {
          data.forEach((date) => {
            if (!clone[index][key]?.["disabled"]) {
              clone[index][date] = {
                value:
                  row?.[date]?.value ||
                  (type === "people" ? row?.annualSalary / 12 : 0),
                disabled: false,
                manualInput: row?.[date]?.["manualInput"] || false,
              };
              if (type === "people") {
                let payrollTiming = currentEntity?.employees?.payrollTiming;
                let cashValue =
                  row?.[date]?.["cashValue"] ||
                  (row?.annualSalary /
                    (payrollTiming === "Weekly"
                      ? 52
                      : payrollTiming === "Monthly"
                      ? 12
                      : 26)) *
                    (payrollTiming === "Monthly"
                      ? 1
                      : payrollRows?.rows?.[2]?.[date]);
                clone[index][date]["cashValue"] = cashValue;
                if (spreadSheet?.people?.paymentType === "Cash") {
                  clone[index][date]["value"] = cashValue;
                }
              }
            }
          });
        }
      });
    });
    var peopleClone;
    if (type === "people") {
      peopleClone = _.cloneDeep(clone);
    }

    let newColumns = [...filteredColumns, ...additionalColumns];
    let newHeaders = {
      ...value.headers,
      cells: [
        ...value.headers.cells.filter((val) =>
          (type === "people"
            ? DEFAULT_PEOPLE_CELL_VALUES
            : DEFAULT_CELL_VALUES
          ).includes(val.text)
        ),
        ...headerAdditionalData,
      ],
    };

    let reevaluatedData = [];

    // for xero and input metrics
    let mappedClone = clone.map((row) => {
      if (
        (row.rowType === ROW_TYPE.ACCOUNT && row.metricType === "Xero") ||
        (row.rowType === ROW_TYPE.METRIC && row.metricType === "Input") ||
        type !== "people"
      ) {
        return handleValueCalculationsForecastIncluded(
          row.metricType,
          clone,
          newColumns,
          clone,
          row,
          "rangeChange",
          false,
          null,
          false,
          "row",
          null,
          dateRange
        );
      }
      return row;
    });

    //  for calculation metrics
    let calculationMappedClone = mappedClone.map((row) => {
      if (
        (row.rowType === ROW_TYPE.METRIC && row.metricType === "Calculation") ||
        (row.rowType === ROW_TYPE.ACCOUNT &&
          row.metricType === "Calculation") ||
        type !== "people"
      ) {
        return handleValueCalculationsForecastIncluded(
          row.metricType,
          mappedClone,
          newColumns,
          mappedClone,
          row,
          "rangeChange",
          false,
          null,
          false,
          "row",
          null,
          dateRange
        );
      }
      return row;
    });
    reevaluatedData = handleSumRowsHelper(
      calculationMappedClone,
      calculationMappedClone
    );
    return {
      ...value,
      rows: type === "people" ? peopleClone : reevaluatedData,
      columns: newColumns,
      headers: newHeaders,
      ...(type === "people" && {
        payrollInformation: generatePayrollInformation(
          additionalColumns,
          peopleClone
        ),
      }),
    };
  };

  const updateDuration = (dateRange, revenue, expense, people, cash) => {
    handleUpdate(
      {
        ...spreadSheet,
        ...dateRange,
        revenue,
        expense,
        people,
        cash,
        durationAdded: true,
      },
      true,
      false,
      value,
      tabType.all
    );
  };

  const generatePayrollInformation = (additionalColumns, peopleData) => {
    const constantColumns = [
      {
        field: "metric",
        headerName: "Metric",
        width: 300,
      },
    ];

    const dynamicColumns = additionalColumns.map((column) => {
      const { columnId, width } = column;
      const headerName = dayjs(columnId).format("MMM YYYY");
      return {
        field: columnId,
        headerName: headerName,
        width: width,
      };
    });

    const columns = [...constantColumns, ...dynamicColumns];

    const rows = [];
    const metrics = [
      "Total Headcount",
      "Total Wage",
      "Pays in month",
      "Superannuation rate",
      "Superannuation Expense",
      "Superannuation Payment",
    ];
    let id = 1;
    metrics.forEach((metric) => {
      const row = {
        id: id++,
        metric: metric,
      };
      additionalColumns.forEach((column) => {
        row[column.columnId] = 0;
      });
      rows.push(row);
    });
    let rowData = peoplePayrollCalculation(
      rows,
      currentEntity,
      dateRange.viewEnd,
      peopleData,
      { rows: peopleData }
    );
    return { columns: columns, rows: rowData };
  };

  //handle date change from duration filter menu
  const handleRangeChange = (override = false) => {
    if (
      moment(dateRange.viewStart) > moment(dateRange.viewEnd) ||
      moment(dateRange.viewStart) > moment(dateRange.durationEnd) ||
      moment(dateRange.viewEnd) > moment(dateRange.durationEnd)
    ) {
      return;
    }
    let startDate = "";
    let endDate = "";
    if (override) {
      startDate = moment(dateRange.durationStart).clone();
      endDate = moment(dateRange.durationEnd).clone();
    } else {
      startDate = moment(dateRange.viewStart).clone();
      endDate = moment(dateRange.viewEnd).clone();
    }
    let betweenMonths = [];

    if (startDate <= endDate) {
      var date = startDate.startOf("month");
      while (date <= endDate.startOf("month")) {
        betweenMonths.push(moment(date.format("YYYY-MM")));
        date.add(1, "month");
      }
    }

    let data = betweenMonths.map((date) => {
      return { year: date.year(), month: monthName[date.month() + 1] };
    });

    let revenue = getUpdatedRowsColumnsHeader("revenue", data);
    let expense = getUpdatedRowsColumnsHeader("expense", data);
    let people = getUpdatedRowsColumnsHeader("people", data);
    let cash = handleCashRows(expense, revenue, data);
    if (spreadSheet.durationAdded || override) {
      let rangeValue = { ...dateRange };
      if (override) {
        rangeValue.viewEnd = rangeValue.durationEnd;
        rangeValue.viewStart = rangeValue.durationStart;
      }
      updateDuration(rangeValue, revenue, expense, people, cash);
    } else {
      setDurationAddModal(true);
    }
  };

  const handleCashRows = (expense, revenue, data) => {
    const value = _.cloneDeep(spreadSheet?.cash);
    const additionalColumns = data.map((value) => {
      return {
        columnId: value.month.toLowerCase() + value.year,
        width: 100,
        resizable: true,
      };
    });
    let filteredColumns = value?.columns.filter((column) =>
      ["sno", "title"].includes(column?.columnId)
    );

    const headerAdditionalData = data.map((value) => {
      return {
        text: value.month + " " + value.year,
        type: "header",
      };
    });

    data = data.map((date) => date.month.toLowerCase() + date.year);
    let clone = _.cloneDeep(value.rows);
    value?.rows.forEach((row, index) => {
      const referenceObject = [...expense?.rows, ...revenue?.rows].find(
        (item) => item.id === row?.refId
      );
      Object.keys(row).forEach((key) => {
        if (DEFAULT_KEYS.includes(key)) {
          data.forEach((date) => {
            if (
              (clone[index][key]?.rowType !== ROW_TYPE.TOTAL_ROW ||
                clone[index][key]?.rowType !== ROW_TYPE.SUB_TOTAL_ROW) &&
              !row?.[date]?.["manualInput"]
            ) {
              clone[index][date] = {
                value: referenceObject?.date?.value || row?.initialValue || 0,
                disabled: false,
                manualInput: row?.[date]?.["manualInput"] || false,
              };
            }
          });
        }
      });
    });

    let newColumns = [...filteredColumns, ...additionalColumns];
    let newHeaders = {
      ...value.headers,
      cells: [
        ...value.headers.cells.filter((val) =>
          ["S.N", "Title"].includes(val.text)
        ),
        ...headerAdditionalData,
      ],
    };

    return {
      ...value,
      rows: clone,
      columns: newColumns,
      headers: newHeaders,
    };
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedSyncItems({ revenue: [], expense: [] });
  };

  const handleSyncXeroWithSelectedAccounts = () => {
    dispatch(
      syncSpreadsheet({
        spreadSheetId,
        unSyncedRows: {
          revenue: [
            ...selectedSyncItems.revenue,
            ...defaultSelectedSyncItems.revenue,
          ],
          expense: [
            ...selectedSyncItems.expense,
            ...defaultSelectedSyncItems.expense,
          ],
        },
        accountingFirmId,
      })
    );
    handleModalClose();
  };

  const handleCheckBoxSelect = (id, name, type) => {
    if (type === "revenue") {
      if (selectedSyncItems.revenue.map((val) => val.id).includes(id)) {
        let index = selectedSyncItems.revenue.map((val) => val.id).indexOf(id);
        let clone = _.cloneDeep(selectedSyncItems.revenue);
        clone.splice(index, 1);
        setSelectedSyncItems({
          ...selectedSyncItems,
          revenue: clone,
        });
      } else {
        setSelectedSyncItems({
          ...selectedSyncItems,
          revenue: [...selectedSyncItems.revenue, { id, name }],
        });
      }
    } else {
      if (selectedSyncItems.expense.map((val) => val.id).includes(id)) {
        let index = selectedSyncItems.expense.map((val) => val.id).indexOf(id);
        let clone = _.cloneDeep(selectedSyncItems.expense);
        clone.splice(index, 1);
        setSelectedSyncItems({
          ...selectedSyncItems,
          expense: clone,
        });
      } else {
        setSelectedSyncItems({
          ...selectedSyncItems,
          expense: [...selectedSyncItems.expense, { id, name }],
        });
      }
    }
  };

  const getNameList = () => {
    let nameList = [];
    spreadSheet?.revenue?.rows?.forEach((data) => {
      nameList.push(data?.metricName?.trim());
    });
    spreadSheet.expense?.rows?.forEach((data) => {
      nameList?.push(data?.metricName?.trim());
    });
    return nameList;
  };

  const handleCashAccrualChange = (currentType, tabCalculationType) => {
    dispatch(setRefreshing());
    let payrollRows = peoplePayrollCalculation(
      spreadSheet?.people?.payrollInformation?.rows,
      currentEntity,
      spreadSheet?.viewEnd,
      spreadSheet.people.rows,
      { rows: spreadSheet.people.rows }
    );
    let peopleRows = spreadSheet.people.rows.map((row) => {
      const { updatedSideNavData } = calculateMonthlySalary(
        row.changeHistory,
        false,
        row,
        currentEntity,
        spreadSheet.viewEnd,
        row,
        spreadSheet.viewEnd,
        spreadSheet.viewStart,
        payrollRows,
        currentType
      );
      return updatedSideNavData;
    });

    payrollRows = peoplePayrollCalculation(
      spreadSheet?.people?.payrollInformation?.rows,
      currentEntity,
      spreadSheet?.viewEnd,
      peopleRows,
      { rows: peopleRows }
    );

    let { spreadsheet } = handleReferenceUpdate(
      {
        ...spreadSheet,
        people: {
          ...spreadSheet?.people,
          rows: peopleRows,
          payrollInformation: {
            ...spreadSheet?.people?.payrollInformation,
            rows: payrollRows,
          },
          paymentType: currentType ? "Accrual" : "Cash",
        },
      },
      peopleRows,
      "people",
      dateRange,
      dispatch,
      setSpreadsheetDataReference,
      currentEntity,
      tabCalculationType
    );

    dispatch(
      updateSpreadsheet({
        payload: {
          value: { ...spreadsheet, activeTab: value },
          refreshData: true,
          entityId,
        },
        spreadSheetId,
        accountingFirmId,
      })
    );
  };

  return (
    <div className="mt-20">
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box sx={{ ...style, width: "800px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={9} display={"flex"}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Select Accounts to be synced
                  </Typography>
                  <Tooltip
                    title={
                      <Typography variant="body1">
                        The default selected accounts must be synced as it is a
                        case of duplicate account for revenue and expense!
                      </Typography>
                    }
                  >
                    <Info className="ml-10" style={{ marginTop: "2.5px" }} />
                  </Tooltip>
                </Grid>
                <Grid item xs={3}>
                  <Grid container justifyContent={"flex-end"}>
                    <IconButton size="small" onClick={handleModalClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-10">
              <Grid container>
                <Grid item xs={5.5}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        id="modal-modal-title"
                        variant="body1"
                        component="h2"
                      >
                        Revenue
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ maxHeight: "300px", overflow: "auto" }}
                    >
                      {possibleSyncItems.revenue.length === 0 && (
                        <div className="mt-10 center-div-message">
                          No revenue items to Sync
                        </div>
                      )}
                      {possibleSyncItems.revenue.map((item) => {
                        return (
                          <ul style={{ paddingLeft: "0px" }} key={item.id}>
                            <Checkbox
                              checked={
                                selectedSyncItems.revenue
                                  .map((val) => val.id)
                                  .includes(item.id) ||
                                defaultSelectedSyncItems.revenue
                                  .map((val) => val.id)
                                  .includes(item.id)
                              }
                              disabled={defaultSelectedSyncItems.revenue
                                .map((val) => val.id)
                                .includes(item.id)}
                              onClick={() => {
                                handleCheckBoxSelect(
                                  item.id,
                                  item.metricName,
                                  "revenue"
                                );
                              }}
                            />
                            {item.metricName}
                          </ul>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>

                <Divider
                  style={{ maxHeight: "300px" }}
                  className="ml-20 mt-20"
                  orientation="vertical"
                  flexItem
                />

                <Grid item xs={5.5} className="ml-10">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        id="modal-modal-title"
                        variant="body1"
                        component="h2"
                      >
                        Expense
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ maxHeight: "300px", overflow: "auto" }}
                    >
                      {possibleSyncItems.expense.length === 0 && (
                        <div className="mt-10 center-div-message ">
                          No expense items to Sync
                        </div>
                      )}
                      {possibleSyncItems.expense.map((item) => {
                        return (
                          <ul style={{ paddingLeft: "0px" }} key={item.id}>
                            <Checkbox
                              checked={
                                selectedSyncItems.expense
                                  .map((val) => val.id)
                                  .includes(item.id) ||
                                defaultSelectedSyncItems.expense
                                  .map((val) => val.id)
                                  .includes(item.id)
                              }
                              disabled={defaultSelectedSyncItems.expense
                                .map((val) => val.id)
                                .includes(item.id)}
                              onClick={() => {
                                handleCheckBoxSelect(
                                  item.id,
                                  item.metricName,
                                  "expense"
                                );
                              }}
                            />
                            {item.metricName}
                          </ul>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={"flex-end"}>
                <Button variant="outlined" onClick={handleModalClose}>
                  Cancel
                </Button>
                <Button
                  className="ml-10"
                  variant="contained"
                  onClick={handleSyncXeroWithSelectedAccounts}
                >
                  Sync
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ConfirmModal
        modalOpen={durationAddModal}
        message="Are you sure you want to save the duration? The start date cannot be changed once it has been saved!"
        confirmText="Yes"
        declineTex="No"
        setModalOpen={setDurationAddModal}
        confirmFunction={() => {
          handleRangeChange(true);
          setDurationAddModal(false);
        }}
        declineFunction={() => {
          setDurationAddModal(false);
        }}
      />
      <ConfirmModal
        modalOpen={paymentTypeChangeConfirm}
        message="Are you sure you want to change the payment type? This will bring change to the entire data!"
        confirmText="Yes"
        declineTex="No"
        setModalOpen={setPaymentTypeConfirm}
        confirmFunction={() => {
          setIsCashorAccrualType(!isCashorAccrualType);
          handleCashAccrualChange(!isCashorAccrualType, tabType.people);
          setPaymentTypeConfirm(false);
        }}
        declineFunction={() => {
          setPaymentTypeConfirm(false);
        }}
      />
      <ConfirmModal
        modalOpen={updatedFinancialSettingModal}
        message="There has been a recent change in financial settings. Press Yes to sync to the latest changes!"
        confirmText="Yes"
        declineText="No"
        confirmDisabled={isFetchingEntity}
        setModalOpen={setUpdatedFinancialSettingModal}
        confirmFunction={() => {
          handleCashAccrualChange(isCashorAccrualType, tabType.all);
          setUpdatedFinancialSettingModal(false);
        }}
        declineFunction={() => {
          setUpdatedFinancialSettingModal(false);
        }}
      />
      {isFetching && <Loading />}
      {isRefreshing && <Loading wait={true} />}
      {!isFetching && (
        <div>
          <Grid container>
            <Grid item xs={6}>
              <Tabs
                value={value}
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
              >
                <StyledTab
                  label="Revenue"
                  style={getTabStyle(value === 0)}
                  onClick={() => setSelected(0)}
                />
                <StyledTab
                  label="Expense"
                  style={{
                    ...getTabStyle(value === 1),
                    borderLeft: "0.5px solid #1976d2",
                    borderRight: "0.5px solid #1976d2",
                  }}
                  onClick={() => setSelected(1)}
                />
                <StyledTab
                  label="People"
                  style={{
                    ...getTabStyle(value === 2),
                    borderLeft: "2px solid #1976d2",
                    borderRight: "0.5px solid #1976d2",
                  }}
                  onClick={() => setSelected(2)}
                />
                <StyledTab
                  label="Cash"
                  style={{
                    ...getTabStyle(value === 3),
                    borderRight: "2px solid #1976d2",
                  }}
                  onClick={() => setSelected(3)}
                />
              </Tabs>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent={"right"}>
                <Grid item className="mr-10">
                  {value === 2 && spreadSheet?.durationAdded && (
                    <IconButton
                      className="mt-5"
                      onClick={() => setDataUploadOpen(true)}
                      variant="outlined"
                      size="small"
                    >
                      <Tooltip
                        title={
                          <p style={{ fontSize: "15px" }}>
                            Bulk Employee Data Upload
                          </p>
                        }
                      >
                        <Upload />
                      </Tooltip>
                    </IconButton>
                  )}
                </Grid>

                {value === 2 && spreadSheet?.durationAdded && (
                  <Grid item className="mr-10">
                    <Grid container mt={1}>
                      <Typography>Cash</Typography>
                      <CustomSwitch
                        check={isCashorAccrualType}
                        handleCheck={() => {
                          setPaymentTypeConfirm(true);
                        }}
                      />
                      <Typography>Accrual</Typography>
                    </Grid>
                  </Grid>
                )}

                <Grid item className="mr-10">
                  {value === 2 && spreadSheet?.durationAdded && (
                    <Button
                      className="mt-5"
                      size="small"
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={() => {
                        setAddEmployeeOpen(!addEmployeeOpen);
                      }}
                    >
                      Add New Employee
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="mr-20"
                  >
                    <Settings />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {value !== 3 && (
                      <MenuItem onClick={handleMetricSetting}>
                        {metricSettingsOpen ? (
                          <VisibilityOff className="mr-10" />
                        ) : (
                          <Visibility className="mr-10" />
                        )}
                        {metricSettingsOpen ? "Hide " : "Show "}
                        {value === 2 ? "Employees " : "Metric "}
                        Settings
                      </MenuItem>
                    )}
                    {value !== 3 && (
                      <MenuItem onClick={handlePriorPeriod}>
                        {priorPeriodOpen ? (
                          <VisibilityOff className="mr-10" />
                        ) : (
                          <Visibility className="mr-10" />
                        )}
                        {priorPeriodOpen ? "Hide " : "Show "}
                        Prior Periods
                      </MenuItem>
                    )}
                    {value === 2 && (
                      <MenuItem onClick={handleTerminated}>
                        {showTerminated ? (
                          <VisibilityOff className="mr-10" />
                        ) : (
                          <Visibility className="mr-10" />
                        )}
                        {showTerminated ? "Show All " : "Show Terminated"}
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleSettings}>
                      {settingsOpen ? (
                        <VisibilityOff className="mr-10" />
                      ) : (
                        <Visibility className="mr-10" />
                      )}
                      {settingsOpen ? "Hide " : "Show "}
                      Duration Filter
                    </MenuItem>
                    <MenuItem
                      style={{
                        display: value === 2 || value === 3 ? "none" : "flex",
                      }}
                      onClick={handleSyncSpreadsheet}
                    >
                      <ReplayOutlined className="mr-10" />
                      Sync with CoA
                    </MenuItem>
                    <MenuItem
                      style={{
                        display: value === 2 || value === 3 ? "none" : "flex",
                      }}
                      onClick={handleSyncPriorPeriod}
                    >
                      <ReplayOutlined className="mr-10" />
                      Sync Prior Periods
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {settingsOpen && (
            <Card className="mb-10 mt-10">
              <Grid container padding={1}>
                <Grid item xs={12}>
                  <Grid container className="mb-10">
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item className="mr-10">
                          <InputLabel style={{ fontSize: "14px" }}>
                            Timing
                          </InputLabel>
                          <TextField
                            select
                            value={dateRange.durationType}
                            size="small"
                            fullWidth
                            onChange={({ target }) => {
                              const { durationStart } = dateRange;
                              setDateRange({
                                ...dateRange,
                                offsetValue: 0,
                                durationType: target.value,
                                durationEnd: durationStart,
                              });
                            }}
                          >
                            <MenuItem value="month">Months</MenuItem>
                            <MenuItem value="year">Years</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item className="mr-10">
                          <InputLabel style={{ fontSize: "14px" }}>
                            No. of
                            {dateRange.durationType === "month"
                              ? " Months"
                              : " Years"}
                          </InputLabel>
                          <TextField
                            size="small"
                            type="number"
                            value={parseInt(dateRange.offsetValue).toString()}
                            fullWidth
                            inputProps={{
                              min: 0,
                              max: dateRange.durationType === "month" ? 60 : 5,
                            }}
                            onChange={({ target }) => {
                              if (
                                (dateRange.durationType === "month" &&
                                  target.value <= 60) ||
                                (dateRange.durationType === "year" &&
                                  target.value <= 5)
                              ) {
                                const { durationStart, durationType } =
                                  dateRange;
                                let end = moment(durationStart)
                                  .clone()
                                  .add(target.value - 1, durationType);
                                setDateRange({
                                  ...dateRange,
                                  offsetValue: parseInt(target.value),
                                  durationEnd:
                                    end.year() + "-" + (end.month() + 1),
                                });
                              }
                            }}
                          ></TextField>
                        </Grid>

                        <Grid item className="mr-10">
                          <InputLabel style={{ fontSize: "14px" }}>
                            Start Date
                          </InputLabel>
                          <DatePicker
                            disabled={spreadSheet.durationAdded}
                            format={"MMMM YYYY"}
                            allowClear={false}
                            size="large"
                            value={dayjs(dateRange.durationStart)}
                            picker="month"
                            onChange={(date, dateString) => {
                              let start = moment(dateString);
                              let { offsetValue, durationType } = dateRange;
                              let end = start
                                .clone()
                                .add(offsetValue - 1, durationType);
                              setDateRange({
                                ...dateRange,
                                durationStart: dateString,
                                durationEnd:
                                  end.year() + "-" + (end.month() + 1),
                              });
                            }}
                          />
                        </Grid>
                        {dateRange?.offsetValue !== 0 && (
                          <Grid item className="ml-10">
                            {" "}
                            <Typography className="mt-25 mr-10">
                              and ending{" "}
                              {
                                monthName[
                                  moment(dateRange?.durationEnd).month() + 1
                                ]
                              }{" "}
                              {moment(dateRange?.durationEnd).year()}
                            </Typography>
                          </Grid>
                        )}

                        {spreadSheet.durationAdded && (
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            className="mr-10 ml-10 mt-5 p-0 m-0"
                            style={{ borderWidth: "2px" }}
                          />
                        )}

                        {spreadSheet.durationAdded && (
                          <Grid item xs={3} className="ml-20">
                            <Grid container>
                              <Grid item xs>
                                <InputLabel style={{ fontSize: "14px" }}>
                                  View Range
                                </InputLabel>
                                <RangePicker
                                  style={{ width: "100%" }}
                                  minDate={dayjs(dateRange?.durationStart)}
                                  maxDate={dayjs(dateRange?.durationEnd)}
                                  format={"MMMM YYYY"}
                                  allowClear={false}
                                  size="large"
                                  value={[
                                    dayjs(dateRange?.viewStart),
                                    dayjs(dateRange?.viewEnd),
                                  ]}
                                  picker="month"
                                  onChange={(date, dateString) => {
                                    setDateRange({
                                      ...dateRange,
                                      viewStart: dateString[0],
                                      viewEnd: dateString[1],
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container justifyContent={"flex-end"}>
                        <Grid item>
                          <Button
                            onClick={handleResetSettings}
                            className="mt-20 mr-10"
                            size="small"
                            variant="outlined"
                            disabled
                          >
                            Reset
                          </Button>
                          <Button
                            onClick={() => handleRangeChange()}
                            className="mt-20 "
                            size="small"
                            variant="contained"
                            disabled={dateRange.offsetValue === 0}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )}

          <>
            {value === 0 && spreadSheet?.revenue && (
              <Revenue
                revenueData={spreadSheet?.revenue}
                settingsOpen={settingsOpen.revenue}
                entityId={entityId}
                spreadSheetId={spreadSheetId}
                durationAdded={
                  spreadSheet?.durationStart !== undefined &&
                  spreadSheet?.durationEnd !== undefined
                }
                setTabSwitch={setTabSwitch}
                durationData={{
                  ..._.pick(spreadSheet, [
                    "durationType",
                    "offsetValue",
                    "durationStart",
                    "durationEnd",
                  ]),
                }}
                refreshData={refreshData || tabSwitch}
                accountingFirmId={accountingFirmId}
                nameList={getNameList()}
                spreadSheet={spreadSheet}
                setSpreadSheet={setSpreadSheet}
                tabValue={value}
                currentEntity={currentEntity}
              />
            )}
          </>
          <>
            {value === 1 && (
              <Expense
                expenseData={spreadSheet?.expense}
                settingsOpen={settingsOpen.revenue}
                entityId={entityId}
                spreadSheetId={spreadSheetId}
                durationAdded={
                  spreadSheet?.durationStart !== undefined &&
                  spreadSheet?.durationEnd !== undefined
                }
                setTabSwitch={setTabSwitch}
                durationData={{
                  ..._.pick(spreadSheet, [
                    "durationType",
                    "offsetValue",
                    "durationStart",
                    "durationEnd",
                  ]),
                }}
                refreshData={refreshData || tabSwitch}
                accountingFirmId={accountingFirmId}
                nameList={getNameList()}
                spreadSheet={spreadSheet}
                setSpreadSheet={setSpreadSheet}
                tabValue={value}
                currentEntity={currentEntity}
                sideNavDisableList={spreadSheet?.people?.rows?.map(
                  (val) => val?.mapping?.value
                )}
              />
            )}
          </>
          <>
            {value === 2 && (
              <People
                setAddEmployeeOpen={setAddEmployeeOpen}
                durationStart={spreadSheet?.durationStart}
                durationEnd={spreadSheet?.viewEnd}
                peopleData={spreadSheet?.people}
                settingsOpen={settingsOpen.people}
                entityId={entityId}
                spreadSheetId={spreadSheetId}
                setTabSwitch={setTabSwitch}
                addEmployeeOpen={addEmployeeOpen}
                dataUploadOpen={dataUploadOpen}
                setDataUploadOpen={setDataUploadOpen}
                refreshData={refreshData || tabSwitch}
                accountingFirmId={accountingFirmId}
                durationData={{
                  ..._.pick(spreadSheet, [
                    "durationType",
                    "offsetValue",
                    "durationStart",
                    "durationEnd",
                  ]),
                }}
                spreadSheet={spreadSheet}
                setSpreadSheet={setSpreadSheet}
                showTerminated={showTerminated}
                paymentType={isCashorAccrualType}
                tabValue={value}
                currentEntity={currentEntity}
                isRefreshing={isRefreshing}
              />
            )}
          </>
          <>
            {value === 3 && (
              <Cash
                spreadSheet={spreadSheet}
                cashData={spreadSheet?.cash}
                peopleData={spreadSheet?.people}
                setTabSwitch={setTabSwitch}
                expenseData={spreadSheet?.expense}
                revenueData={spreadSheet?.revenue}
                durationStart={spreadSheet?.durationStart}
                durationEnd={spreadSheet?.viewEnd}
                entityId={entityId}
                spreadSheetId={spreadSheetId}
                durationAdded={
                  spreadSheet?.durationStart !== undefined &&
                  spreadSheet?.durationEnd !== undefined
                }
                durationData={{
                  ..._.pick(spreadSheet, [
                    "durationType",
                    "offsetValue",
                    "durationStart",
                    "durationEnd",
                  ]),
                }}
                accountingFirmId={accountingFirmId}
                refreshData={refreshData || tabSwitch}
                tabValue={value}
                currentEntity={currentEntity}
              />
            )}
          </>
        </div>
      )}
    </div>
  );
}
