import React from "react";
import TableListCard from "../../../../components/TableListCard/TableListCard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const CustomReportTab = () => {
  const dummyData = [
    {
      id: 1,
      reportName: "Revenue 2024",
      reportDescription: "First quater report for 2024",
      createBy: "Dixit",
      createdAt: "2024-10-10",
    },
    {
      id: 2,
      reportName: "Revenue 2024",
      reportDescription: "First quater report for 2024",
      createBy: "Dixit",
      createdAt: "2024-10-10",
    },
    {
      id: 3,
      reportName: "Revenue 2024",
      reportDescription: "First quater report for 2024",
      createBy: "Dixit",
      createdAt: "2024-10-10",
    },
    {
      id: 4,
      reportName: "Revenue 2024",
      reportDescription: "First quater report for 2024",
      createBy: "Dixit",
      createdAt: "2024-10-10",
    },
  ];
  return (
    <div>
      <TableListCard title={"Custom Reoprts"}>
        <div style={{ padding: "20px" }}>
          <TableContainer
            style={{
              height: "70vh",
              overflowY: "auto",
              maxHeight: "60vh",
              maxWidth: {
                xs: 260,
                sm: 290,
                md: 580,
                lg: "inherit",
              },
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>S.N</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Report Name</Typography>
                  </TableCell>
                  <TableCell width={"30%"}>
                    <Typography fontWeight={"bold"}>
                      Report Description
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Created By</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Created At</Typography>
                  </TableCell>
                  <TableCell width={"20%"}>
                    <Typography fontWeight={"bold"}>Actions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dummyData.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{data.id}</TableCell>
                      <TableCell>{data.reportName}</TableCell>
                      <TableCell>{data.reportDescription}</TableCell>
                      <TableCell>{data.createBy}</TableCell>
                      <TableCell>{data.createdAt}</TableCell>
                      <TableCell>
                        <Grid>
                          <Button
                            //   onClick={handleClick}
                            size="small"
                            color="error"
                            id={"more-" + data._id}
                            startIcon={<DeleteOutlineOutlinedIcon />}
                          >
                            Delete
                          </Button>
                          <Button
                            //   onClick={handleClick}
                            size="small"
                            id={"more-" + data._id}
                            startIcon={<FileDownloadOutlinedIcon />}
                          >
                            Load
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </TableListCard>
    </div>
  );
};

export default CustomReportTab;
