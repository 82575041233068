import moment from "moment";
import {
  MONTH_MAP,
  MONTH_MAP_REVERSE,
  DEFAULT_KEYS,
  DEFAULT_COLUMNS,
  ROW_TYPE,
  TIMING_MAP,
} from "./defaultValues";
import _, { cloneDeep } from "lodash";
import dayjs from "dayjs";

export const monthName = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const tabType = {
  revenue: "revenue",
  expense: "expense",
  people: "people",
  cash: "cash",
  all: "all",
  none: "none",
};

export const paygRates = [
  {
    _id: "668cd2144b81d122a207226c",
    paygType: "Scale 1 No STSL Applied",
    value: {
      "0": {
        a: "0",
        b: "0",
      },
      "150": {
        a: "0.16",
        b: "0.16",
      },
      "371": {
        a: "0.2117",
        b: "7.755",
      },
      "515": {
        a: "0.189",
        b: "-0.6702",
      },
      "932": {
        a: "0.3227",
        b: "68.2367",
      },
      "2246": {
        a: "0.32",
        b: "65.7202",
      },
      "3303": {
        a: "0.39",
        b: "222.951",
      },
      "3303 & over": {
        a: "0.47",
        b: "487.2587",
      },
    },
  },
  {
    _id: "668cd3c74b81d122a207226d",

    paygType: "Scale 2 No STSL Applied",
    value: {
      "361": {
        a: "0",
        b: "0",
      },
      "500": {
        a: "0.16",
        b: "57.8462",
      },
      "625": {
        a: "0.26",
        b: "107.8462",
      },
      "721": {
        a: "0.18",
        b: "57.8462",
      },
      "865": {
        a: "0.189",
        b: "64.3365",
      },
      "1282": {
        a: "0.3227",
        b: "180.0385",
      },
      "2596": {
        a: "0.32",
        b: "176.5769",
      },
      "3653": {
        a: "0.39",
        b: "358.3077",
      },
      "3653 & over": {
        a: "0.47",
        b: "650.6154",
      },
    },
  },
  {
    _id: "668ce2f54b81d122a207226e",
    paygType: "Scale 2 STSL Applied",
    value: {
      "1045.99": {
        a: "0.00",
      },
      "1046": {
        a: "1.00",
      },
      "1208": {
        a: "2.00",
      },
      "1281": {
        a: "2.50",
      },
      "1358": {
        a: "3.00",
      },
      "1439": {
        a: "3.50",
      },
      "1525": {
        a: "4.00",
      },
      "1617": {
        a: "4.50",
      },
      "1714": {
        a: "5.00",
      },
      "1817": {
        a: "5.50",
      },
      "1926": {
        a: "6.00",
      },
      "2042": {
        a: "6.50",
      },
      "2164": {
        a: "7.00",
      },
      "2294": {
        a: "7.50",
      },
      "2432": {
        a: "8.00",
      },
      "2578": {
        a: "8.50",
      },
      "2732": {
        a: "9.00",
      },
      "2896": {
        a: "9.50",
      },
      "3070": {
        a: "10.00",
      },
    },
  },
  {
    _id: "668ce4484b81d122a2072270",
    paygType: "Scale 1 STSL Applied",
    value: {
      "695.99": {
        a: "0.00",
      },
      "696": {
        a: "1.00",
      },
      "858": {
        a: "2.00",
      },
      "931": {
        a: "2.50",
      },
      "1008": {
        a: "3.00",
      },
      "1089": {
        a: "3.50",
      },
      "1175": {
        a: "4.00",
      },
      "1267": {
        a: "4.50",
      },
      "1364": {
        a: "5.00",
      },
      "1467": {
        a: "5.50",
      },
      "1576": {
        a: "6.00",
      },
      "1692": {
        a: "6.50",
      },
      "1814": {
        a: "7.00",
      },
      "1944": {
        a: "7.50",
      },
      "2082": {
        a: "8.00",
      },
      "2228": {
        a: "8.50",
      },
      "2382": {
        a: "9.00",
      },
      "2546": {
        a: "9.50",
      },
      "2720": {
        a: "10.00",
      },
    },
  },
];

//convert date to object key (Sep 2024 =>sep2024 )
const getKeyFromDate = (date) => {
  return monthName[date.month() + 1].toLowerCase() + date.year();
};

//calculate intital for side nav from Use prior period as forecast
export const calculateInitialValue = (
  initialValue,
  adjustValue,
  adjustType,
  adjustCalculation
) => {
  let value = 0;
  if (adjustCalculation === "increase") {
    if (adjustType === "percent") {
      value = initialValue + initialValue * (parseFloat(adjustValue) / 100);
    } else {
      value = initialValue + parseInt(adjustValue);
    }
  } else {
    if (adjustType === "percent") {
      value = initialValue - initialValue * (parseFloat(adjustValue) / 100);
    } else {
      value = initialValue - parseFloat(adjustValue);
    }
  }

  return value;
};

//caculate range between start date and end date
export const getTimingRange = (startValue, endValue) => {
  let start =
    moment(startValue).year() + "-" + (moment(startValue).month() + 1);
  let end = moment(endValue).year() + "-" + (moment(endValue).month() + 1);
  var startDate = moment(start?.slice(0, 7));
  var endDate = moment(end?.slice(0, 7));
  var result = [];

  while (startDate.isSameOrBefore(endDate)) {
    result.push(startDate.format("YYYY-MM"));
    startDate.add(1, "month");
  }
  return result.map((date) => {
    return MONTH_MAP[parseInt(date.split("-")[1])] + date.split("-")[0];
  });
};

//return diff between two arrays eg:{[1,2,3] and [1,2] result: [3]}
export const getArrayDiff = (arr1, arr2) => {
  return arr1.filter((x) => !arr2.includes(x));
};

//convert date key to previous year key eg: aug2024 result => aug2023
export const getPrevYearMonthString = (date) => {
  let prevYear = moment(
    date.slice(3, 7) + "-" + MONTH_MAP_REVERSE[date.slice(0, 3)]
  ).subtract(1, "year");
  return MONTH_MAP[prevYear.month() + 1] + prevYear.year();
};

export const getFormulaSymbol = (type) => {
  switch (type) {
    case "Add":
      return "+";
    case "Subtract":
      return "-";
    case "Multiply":
      return "*";
    case "Divide":
      return "/";
    default:
      return "";
  }
};

// calculation for prior period for same month prior period only
export const calculateSameMonthPriorValue = (
  type,
  columns,
  sideNavData,
  cloneData,
  dateRange,
  revertDates,
  forecastSave,
  forecastRange,
  overrideManual,
  durationData
) => {
  let sideNavClone = cloneDeep(cloneData);
  if (type === "first") {
    let dateColumns = columns.reduce((acc, column) => {
      if (!DEFAULT_COLUMNS.includes(column.columnId)) {
        acc.push(column.columnId);
      }
      return acc;
    }, []);
    let priorDataObject = {};
    sideNavData["sameMonthPriorYearData"].forEach((val) => {
      priorDataObject[MONTH_MAP[val.month] + val.year] = val.amount;
    });
    dateColumns?.forEach((date) => {
      let value = sideNavClone?.[date]?.["value"];
      let dateAdjustedString = getPrevYearMonthString(date);
      let dateValue =
        dateRange.indexOf(date) !== -1
          ? priorDataObject?.[dateAdjustedString]
            ? priorDataObject?.[dateAdjustedString]
            : sideNavClone?.[dateAdjustedString]?.["value"]
          : 0;
      dateValue = calculateInitialValue(
        dateValue,
        sideNavData?.priorPeriodValue,
        sideNavData?.priorPeriodType,
        sideNavData?.priorPeriodIncrementType
      );
      if (dateRange.includes(date)) {
        if (
          sideNavClone?.[date]?.["manualInput"] &&
          overrideManual &&
          !revertDates.includes(date)
        ) {
          if (forecastSave === null && !forecastRange.includes(date)) {
            sideNavClone[date]["manualInput"] = false;
            value = dateValue;
          }
        } else {
          if (!sideNavClone?.[date]?.["manualInput"]) {
            value = dateValue;
          }
        }
        sideNavClone[date]["value"] = value;
      } else {
        if (!sideNavClone?.[date]?.["manualInput"]) {
          sideNavClone[date]["value"] = dateValue;
        }
      }
    });
  } else {
    let forecastRange = [];
    sideNavData?.forecastTimings?.forEach((forecast) => {
      forecastRange.push(...getTimingRange(forecast.start, forecast.end));
    });
    let metricRange = getTimingRange(
      sideNavData?.startDate,
      sideNavData?.endDate || durationData?.durationEnd
    );
    let diffRange = getArrayDiff(metricRange, forecastRange);
    diffRange.forEach((date) => {
      let value = sideNavClone?.[date]?.["value"] || 0;
      let dateAdjustedString = getPrevYearMonthString(date);
      let priorDataObject = {};
      sideNavData["sameMonthPriorYearData"].forEach((val) => {
        priorDataObject[MONTH_MAP[val.month] + val.year] = val.amount;
      });
      let dateValue = sideNavClone?.[dateAdjustedString]?.["value"]
        ? sideNavClone?.[dateAdjustedString]?.["value"]
        : priorDataObject[dateAdjustedString];
      dateValue = calculateInitialValue(
        dateValue,
        sideNavData?.priorPeriodValue,
        sideNavData?.priorPeriodType,
        sideNavData?.priorPeriodIncrementType
      );
      if (!sideNavClone?.[date]?.["manualInput"]) {
        value = dateValue;
      }
      sideNavClone[date]["value"] = value;
    });
  }
  return sideNavClone;
};

// calculation for prior period excluding same month prior period
export const calculateOtherFinancialValue = (
  sideNavData,
  cloneData,
  dateRange,
  revertDates,
  forecastSave,
  forecastRange,
  overrideManual
) => {
  let sideNavClone = cloneDeep(cloneData);
  Object.keys(sideNavData).forEach((key) => {
    if (!DEFAULT_KEYS.includes(key)) {
      let dateValue =
        dateRange.indexOf(key) !== -1 ? sideNavData.initialValue : 0;

      if (dateRange.includes(key)) {
        let value = sideNavClone?.[key]?.["value"];
        if (
          sideNavClone?.[key]?.["manualInput"] &&
          overrideManual &&
          !revertDates.includes(key)
        ) {
          if (forecastSave === null && !forecastRange.includes(key)) {
            sideNavClone[key]["manualInput"] = false;
            value = dateValue;
          } else {
            if (!sideNavClone?.[key]?.["manualInput"]) {
              value = dateValue;
            }
          }
        } else {
          if (!sideNavClone?.[key]?.["manualInput"]) {
            value = dateValue;
          }
        }
        sideNavClone[key]["value"] = value;
      } else {
        if (!sideNavClone?.[key]?.["manualInput"]) {
          sideNavClone[key]["value"] = dateValue;
        }
      }
    }
  });

  return sideNavClone;
};
// forecast one time change
export const calculateOneTimeChange = (
  cloneData,
  timingRange,
  forecast,
  forecastSave,
  overrideManual
) => {
  let sideNavClone = cloneDeep(cloneData);
  timingRange.forEach((time) => {
    if (sideNavClone[time]) {
      let value = sideNavClone?.[time]?.["value"];
      if (
        sideNavClone?.[time]?.["manualInput"] &&
        overrideManual &&
        (overrideManual && forecastSave !== null
          ? forecast.id === forecastSave
          : true)
      ) {
        value = parseInt(forecast.changeValue);
        sideNavClone[time]["manualInput"] = false;
      } else {
        if (!sideNavClone?.[time]?.["manualInput"]) {
          value = parseInt(forecast.changeValue);
        }
      }
      sideNavClone[time]["value"] = value;
    }
  });

  return sideNavClone;
};
// forecast growth change

export const calculateGrowthChange = (
  cloneData,
  timingRange,
  initialValue,
  forecast,
  forecastSave,
  overrideManual
) => {
  let sideNavClone = cloneDeep(cloneData);
  let tracker = 0;
  timingRange.forEach((time, index) => {
    if (index % parseInt(forecast.changeDuration) === 0) {
      tracker += 1;
    }
    if (sideNavClone[time]) {
      let value = sideNavClone?.[time]?.["value"];
      if (forecast.changeType === "increase") {
        value =
          forecast.changeFormat === "percent"
            ? parseInt(initialValue) *
              Math.pow(1 + forecast.changeValue / 100, tracker)
            : parseInt(initialValue) + parseInt(forecast.changeValue) * tracker;
      } else {
        value =
          forecast.changeFormat === "percent"
            ? parseInt(initialValue) *
              Math.pow(1 - forecast.changeValue / 100, tracker)
            : parseInt(initialValue) - parseInt(forecast.changeValue) * tracker;
      }
      if (
        sideNavClone?.[time]?.["manualInput"] &&
        overrideManual &&
        (overrideManual && forecastSave !== null
          ? forecast.id === forecastSave
          : true)
      ) {
        sideNavClone[time]["manualInput"] = false;
      } else {
        if (sideNavClone?.[time]?.["manualInput"]) {
          value = sideNavClone?.[time]?.["value"];
        }
      }
      sideNavClone[time]["value"] = value;
    }
  });
  return sideNavClone;
};
// forecast increment change

export const calculateIncrementChange = (
  cloneData,
  timingRange,
  initialValue,
  forecast,
  forecastSave,
  overrideManual
) => {
  let sideNavClone = cloneDeep(cloneData);
  let changeValue = (forecast.changeValue - initialValue) / timingRange.length;
  timingRange.forEach((time, index) => {
    if (sideNavClone[time]) {
      let value = sideNavClone?.[time]?.["value"];
      if (
        sideNavClone?.[time]?.["manualInput"] &&
        overrideManual &&
        (overrideManual && forecastSave !== null
          ? forecast.id === forecastSave
          : true)
      ) {
        value = initialValue + changeValue * (index + 1);
        sideNavClone[time]["manualInput"] = false;
      } else {
        if (!sideNavClone?.[time]?.["manualInput"]) {
          value = initialValue + changeValue * (index + 1);
        }
      }
      sideNavClone[time]["value"] = value;
    }
  });
  return sideNavClone;
};

export const getCalculationMetricValue = (type, metricsList, key) => {
  let value = 0;
  if (type === "Add") {
    value = metricsList.reduce((acc, val) => {
      acc += val[key]["value"] / (val?.valueType === "Percentage" ? 100 : 1);
      return acc;
    }, 0);
  } else if (type === "Subtract") {
    value = metricsList.reduce((acc, val, index) => {
      if (index === 0) {
        acc += val[key]["value"] / (val?.valueType === "Percentage" ? 100 : 1);
      } else {
        acc -= val[key]["value"] / (val?.valueType === "Percentage" ? 100 : 1);
      }
      return acc;
    }, 0);
  } else if (type === "Multiply") {
    value = metricsList.reduce((acc, val) => {
      acc *= val[key]["value"] / (val?.valueType === "Percentage" ? 100 : 1);
      return acc;
    }, 1);
  } else {
    value = metricsList.reduce((acc, val, index) => {
      if (index === 0) {
        acc = val[key]["value"] / (val?.valueType === "Percentage" ? 100 : 1);
      } else {
        acc /= val[key]["value"] / (val?.valueType === "Percentage" ? 100 : 1);
      }
      return acc;
    }, 1);
  }
  return value;
};

export const calculateMetricChildLogic = (
  parentMetricRow,
  rows,
  overrideManual,
  forecastSave,
  inputOrCalculation,
  sideNavData,
  durationData
) => {
  let clone = cloneDeep(rows);
  [...rows?.sort((a, b) => (a.metricType > b.metricType ? -1 : 1))].forEach(
    (metric) => {
      if (parentMetricRow?.accountMetrics?.includes(metric.id)) {
        // calculate all the values for metric of type Input if there are forecast timings for each input
        if (metric?.metricType === "Input" && inputOrCalculation) {
          if (metric?.forecastTimings?.length !== 0) {
            [
              ..._.cloneDeep(metric?.forecastTimings || [])?.sort(
                (a, b) =>
                  Number(new Date(a?.start)) - Number(new Date(b?.start))
              ),
            ]?.forEach((forecast) => {
              let timingRange = getTimingRange(
                forecast.start,
                forecast.end || sideNavData.endDate || durationData.durationEnd
              );
              let initialValue = metric?.initialValue;
              if (forecast.type !== "oneTimeChange") {
                let prevMonth = moment(forecast.start)
                  .clone()
                  .subtract(1, "month");
                let prevMonthString =
                  MONTH_MAP[prevMonth.month() + 1] + prevMonth.year();
                initialValue = clone[metric?.sortOrder - 1][prevMonthString]
                  ? clone[metric?.sortOrder - 1][prevMonthString]?.value
                  : initialValue;
              }
              if (forecast.type === "oneTimeChange") {
                clone[metric?.sortOrder - 1] = calculateOneTimeChange(
                  clone[metric?.sortOrder - 1],
                  timingRange,
                  forecast,
                  forecastSave,
                  overrideManual
                );
              } else if (forecast.type === "growthChange") {
                clone[metric?.sortOrder - 1] = calculateGrowthChange(
                  clone[metric?.sortOrder - 1],
                  timingRange,
                  initialValue,
                  forecast,
                  forecastSave,
                  overrideManual
                );
              } else {
                clone[metric?.sortOrder - 1] = calculateIncrementChange(
                  clone[metric?.sortOrder - 1],
                  timingRange,
                  initialValue,
                  forecast,
                  forecastSave,
                  overrideManual
                );
              }
            });
          }
        }
        // calculate the values for the metric type Calculation
        if (metric?.metricType === "Calculation" && !inputOrCalculation) {
          let calculationMetrics = metric?.formulaId?.map((id) => {
            let metric = clone.find((val) => val.id === id);
            return metric;
          });
          // other calculation in the same parent metric
          let otherChildrenCalculation = clone.filter(
            (val) =>
              val.id !== metric.id &&
              val.metricParentId === metric.metricParentId
          );
          //reevaluate other calculation metrics in same parent to recalculate possible calculation for formula
          let reEvaluatedmetrics = otherChildrenCalculation.map((row) => {
            if (row.metricType === "Calculation") {
              let metrics = row?.formulaId?.map((id) => {
                let metric = clone.find((val) => val.id === id);
                return metric;
              });
              Object.keys(row).forEach((key) => {
                if (!DEFAULT_KEYS.includes(key)) {
                  if (!row?.[key]?.["manualInput"]) {
                    let type = row.calculationType;
                    row[key]["value"] = getCalculationMetricValue(
                      type,
                      metrics,
                      key
                    );
                  }
                }
              });
              return row;
            }
            return row;
          });
          // update the reevaluated values to the rows value
          let reEvaluatedmetricsId = reEvaluatedmetrics.map((val) => val.id);
          clone = clone.map((row) => {
            if (reEvaluatedmetricsId.includes(row.id)) {
              let index = reEvaluatedmetricsId.indexOf(row.id);
              return reEvaluatedmetrics[index];
            }
            return row;
          });
          // add the values of the calculated values based on formula for specific calculation metric
          Object.keys(metric).forEach((key) => {
            if (!DEFAULT_KEYS.includes(key)) {
              if (!clone?.[metric?.sortOrder - 1]?.[key]?.["manualInput"]) {
                let type = metric.calculationType;
                clone[metric?.sortOrder - 1][key][
                  "value"
                ] = getCalculationMetricValue(type, calculationMetrics, key);
              }
            }
          });
        }
      }
    }
  );
  return clone;
};

export const getCalculationRowIntitalData = (
  sideNavData,
  dateRange,
  cloneData,
  overrideManual,
  isForecast
) => {
  let sideNavClone = cloneDeep(cloneData);
  if (sideNavData?.referenceId || sideNavData?.referenceTab) {
    return sideNavClone;
  }
  Object.keys(sideNavData).forEach((key) => {
    if (!DEFAULT_KEYS.includes(key)) {
      if (dateRange.includes(key)) {
        let value = sideNavClone?.[key]?.["value"];
        if (sideNavClone?.[key]?.["manualInput"] && overrideManual) {
          sideNavClone[key]["manualInput"] = false;
          value = sideNavData.initialValue;
        }
        if (!sideNavClone?.[key]?.["manualInput"]) {
          value = sideNavData.initialValue;
        }
        sideNavClone[key]["value"] = value;
      } else {
        if (!sideNavClone?.[key]?.["manualInput"] && !isForecast) {
          sideNavClone[key]["value"] = 0;
        }
      }
    }
  });
  return sideNavClone;
};

export const calculateRowDataSpecificType = (
  type,
  rows,
  columns,
  cloneData, //the rows value that are cloned through clonedeep
  sideNavData, //each rows
  initialSave,
  overrideManual,
  forecastSave,
  clearMetric,
  returnType,
  prevForecast,
  durationData
  // forecastMetricRange
) => {
  let index = rows?.map((val) => val?.id)?.indexOf(sideNavData?.id);
  let clone = _.cloneDeep(cloneData);
  let sideNavClone = _.cloneDeep(sideNavData);
  let dateRange = getTimingRange(
    sideNavData.startDate,
    sideNavData.endDate || durationData?.durationEnd
  );
  let revertDates = [];
  let forecastRange = prevForecast
    ? getTimingRange(prevForecast?.start, prevForecast?.end)
    : [];
  // clear the entire row values
  if (clearMetric) {
    Object.keys(sideNavData).forEach((key) => {
      if (!DEFAULT_KEYS.includes(key)) {
        sideNavClone[key]["manualInput"] = false;
        sideNavClone[key]["value"] = 0;
      }
    });
    clone[index] = sideNavClone;
  } else {
    //calculate the entire row logic for Xero metric type
    if (type === "Xero") {
      if (forecastSave === null || prevForecast !== null) {
        if (prevForecast !== null) {
          let forecast = sideNavData.forecastTimings?.find(
            (forecast) => forecast?.id === forecastSave
          );
          revertDates = getArrayDiff(
            getTimingRange(prevForecast?.start, prevForecast?.end),
            getTimingRange(forecast?.start, forecast?.end)
          );
        }
        //calculate the row data for other prior period values
        if (sideNavData.priorPeriod !== "sameMonthPriorYearData") {
          sideNavClone = calculateOtherFinancialValue(
            sideNavData,
            sideNavClone,
            dateRange,
            revertDates,
            forecastSave,
            forecastRange,
            overrideManual
          );
        }
        //calculate the row data for same month prior year values
        else {
          sideNavClone = calculateSameMonthPriorValue(
            "first",
            columns,
            sideNavData,
            sideNavClone,
            dateRange,
            revertDates,
            forecastSave,
            forecastRange,
            overrideManual,
            durationData
          );
        }
      }
      [
        ..._.cloneDeep(sideNavData?.forecastTimings || [])?.sort(
          (a, b) => Number(new Date(a?.start)) - Number(new Date(b?.start))
        ),
      ]?.forEach((forecast) => {
        let timingRange = getTimingRange(
          forecast?.start,
          forecast?.end || sideNavData?.endDate || durationData?.durationEnd
        );
        let initialValue = sideNavData?.initialValue;

        if (forecast.type !== "oneTimeChange") {
          let prevMonth = moment(forecast?.start).clone().subtract(1, "month");
          let prevMonthString =
            MONTH_MAP[prevMonth.month() + 1] + prevMonth.year();
          initialValue = sideNavClone?.[prevMonthString]
            ? sideNavClone?.[prevMonthString]?.value
            : initialValue;
        }
        if (forecast.type === "oneTimeChange") {
          sideNavClone = calculateOneTimeChange(
            sideNavClone,
            timingRange,
            forecast,
            forecastSave,
            overrideManual
          );
        } else if (forecast.type === "growthChange") {
          sideNavClone = calculateGrowthChange(
            sideNavClone,
            timingRange,
            initialValue,
            forecast,
            forecastSave,
            overrideManual
          );
        } else {
          sideNavClone = calculateIncrementChange(
            sideNavClone,
            timingRange,
            initialValue,
            forecast,
            forecastSave,
            overrideManual
          );
        }
      });
      if (sideNavData.priorPeriod === "sameMonthPriorYearData") {
        sideNavClone = calculateSameMonthPriorValue(
          "second",
          columns,
          sideNavData,
          sideNavClone,
          dateRange,
          revertDates,
          forecastSave,
          forecastRange,
          overrideManual,
          durationData
        );
      }
      clone[index] = sideNavClone;
    }
    //calculate the entire row logic for Calculation metric type
    else {
      if (sideNavData?.rowType !== ROW_TYPE.ACCOUNT) {
        // if (forecastMetricRange) {
        //   sideNavClone = getCalculationRowIntitalData(
        //     sideNavData,
        //     forecastMetricRange,
        //     sideNavClone,
        //     overrideManual,
        //     true
        //   );
        // } else {
        sideNavClone = getCalculationRowIntitalData(
          sideNavData,
          dateRange,
          sideNavClone,
          overrideManual,
          false
        );
        // }
      }
      clone[index] = sideNavClone;
      // check if the side nav data is account or account metrics
      let parentId =
        sideNavData?.rowType === ROW_TYPE.ACCOUNT
          ? sideNavData?.id
          : sideNavData?.metricParentId;

      let parentMetricRow = clone.find((row) => row?.id === parentId);
      // evaluate value for metric of type Input
      let inputCalculatedRows = [
        ...calculateMetricChildLogic(
          parentMetricRow,
          clone,
          overrideManual,
          forecastSave,
          true,
          sideNavData,
          durationData
        ),
      ];
      // evaluate value for metric of type Calculation
      let calculationCalculatedRows = [
        ...calculateMetricChildLogic(
          parentMetricRow,
          inputCalculatedRows,
          overrideManual,
          forecastSave,
          false,
          sideNavData,
          durationData
        ),
      ];
      // update the rows to have the latest value with all input and calculation evaluated
      clone = calculationCalculatedRows;
      // replicate value of the Revenue row to the parent calculation metric
      clone.forEach((val, index) => {
        if (
          val?.metricType === "Calculation" &&
          val?.rowType === ROW_TYPE.ACCOUNT
        ) {
          let childCalculationMetric = clone?.find(
            (child) => child?.id === val?.accountMetrics[0]
          );
          if (childCalculationMetric) {
            Object.keys(childCalculationMetric)?.forEach((key) => {
              if (!DEFAULT_KEYS.includes(key)) {
                if (sideNavClone?.[key]?.["manualInput"] && overrideManual) {
                  clone[index][key]["value"] =
                    childCalculationMetric?.[key]?.["value"];
                  clone[index][key]["manualInput"] = false;
                  if (sideNavClone?.id === clone?.[index]?.id) {
                    sideNavClone[key]["value"] =
                      childCalculationMetric?.[key]?.["value"];
                    sideNavClone[key]["manualInput"] = false;
                  }
                }
                if (!clone?.[index]?.[key]?.["manualInput"]) {
                  clone[index][key]["value"] =
                    childCalculationMetric?.[key]?.["value"];
                  if (sideNavClone?.id === clone?.[index]?.id) {
                    sideNavClone[key]["value"] =
                      childCalculationMetric?.[key]?.["value"];
                  }
                }
              }
            });
          }
        }
      });
    }
  }

  if (initialSave === "reset") {
    sideNavClone["initialSave"] = false;
  } else if (initialSave === "saved") {
    sideNavClone["initialSave"] = true;
  }

  return returnType === "rows" ? clone : clone?.[index];
};

export const handleValueCalculationsForecastIncluded = (
  type,
  rows, //revenue or expense rows
  columns, //revenue or expense columns
  cloneData, //revenue or expense rows clone data
  sideNavData, //revenue or expense selected row
  initialSave, // first save for the row
  overrideManual, // if manual input is to be overriden
  forecastSave = null, // forecast timing save
  clearMetric = false, // clear metric
  returnType = "rows", //either return row or rows
  prevForecast = null, // if previous forecast exists
  durationData, // spreadsheet duration value
  forecastMetricRange // range for forecast metric
) => {
  let returnData = calculateRowDataSpecificType(
    type,
    rows,
    columns,
    cloneData,
    sideNavData,
    initialSave,
    overrideManual,
    forecastSave,
    clearMetric,
    returnType,
    prevForecast,
    durationData,
    forecastMetricRange
  );
  return returnData;
};

export const handleSumRowsHelper = (value, clone) => {
  if (!Array.isArray(clone) || clone?.length === 0) {
    return value;
  }
  //sums up the children accounts for a account group
  value.forEach((row, index) => {
    if (row?.rowType === ROW_TYPE.ACCOUNT_GROUP) {
      Object.keys(row).forEach((key) => {
        if (!DEFAULT_KEYS.includes(key)) {
          let sum = 0;
          clone
            .filter(
              (val) => val?.subParentId === row?.id && !val?.metricParentId
            )
            .forEach((val) => {
              sum += val?.[key]?.value || 0;
            });
          clone[index][key].value = sum;
        }
      });
    }
  });
  //sums up children account and account group for a default metric
  value.forEach((row, index) => {
    if (row?.rowType === ROW_TYPE.CUSTOM_METRIC) {
      Object.keys(row).forEach((key) => {
        if (!DEFAULT_KEYS.includes(key)) {
          let sum = 0;
          clone
            .filter(
              (val) =>
                val?.parentId === row?.id &&
                !val?.subParentId &&
                !val?.metricParentId
            )
            .forEach((val) => {
              sum += val?.[key]?.value || 0;
            });

          clone[index][key].value = sum;
        }
      });
    }
  });
  //sums up the default metric values for total row value
  Object.keys(value[0]).forEach((key) => {
    if (!DEFAULT_KEYS.includes(key)) {
      let sum = 0;
      clone
        ?.filter((val) => val?.rowType === ROW_TYPE.CUSTOM_METRIC)
        .forEach((val) => {
          sum += val?.[key]?.value || 0;
        });
      clone[0][key].value = sum;
    }
  });
  return clone;
};

export const getForecastName = (forecast) => {
  let name = `${forecast?.name} (
    ${TIMING_MAP[forecast?.type]})
    From${" "}
    ${
      MONTH_MAP[parseInt(forecast?.start?.split("-")[1])]?.toUpperCase() +
      " " +
      forecast?.start?.split("-")[0]
    }${" "}
    `;
  name += forecast.end
    ? `
    To${" "}
    ${
      MONTH_MAP[parseInt(forecast?.end?.split("-")[1])]?.toUpperCase() +
      " " +
      forecast?.end?.split("-")[0]
    }`
    : "";
  return name;
};
//handles all the reference logic calculations
export const handleReferenceUpdate = (
  spreadsheet,
  currentTabRowData,
  currentTabType,
  durationData,
  dispatch,
  refreshData,
  currentEntity,
  tabCalculationType
) => {
  let tabs = ["revenue", "expense"];
  let clone = _.cloneDeep(spreadsheet);
  let refreshNecessary = false;
  clone[currentTabType].rows = currentTabRowData;
  let overallRows = [
    ...(clone?.["revenue"]?.rows || []),
    ...(clone?.["expense"]?.rows || []),
    ...(clone?.["people"]?.rows || []),
  ];
  if (tabCalculationType !== tabType.none) {
    tabs.forEach((tab) => {
      clone[tab].rows.forEach((row, index) => {
        if (row?.referenceId && row?.referenceTab) {
          refreshNecessary = true;
          let referedRow = overallRows?.find(
            (val) => val.id === row.referenceId
          );
          Object.keys(row).forEach((key) => {
            if (!DEFAULT_KEYS.includes(key)) {
              if (!row?.[key]?.["manualInput"]) {
                clone[tab]["rows"][index][key]["value"] =
                  referedRow?.[key]?.["value"] || 0;
              }
            }
          });

          clone[tab].rows = handleValueCalculationsForecastIncluded(
            row?.metricType,
            clone[tab].rows,
            clone[tab].columns,
            _.cloneDeep(clone[tab].rows),
            clone[tab].rows[index],
            "saved",
            false,
            null,
            false,
            "rows",
            null,
            durationData
          );
          clone[tab].rows = handleSumRowsHelper(
            clone[tab]?.rows,
            clone[tab]?.rows
          );
        }

        if (row?.cashTiming !== undefined) {
          let cashRowIndex = clone["cash"]["rows"].findIndex(
            (val) => val.refId === row.id
          );
          let cashRowDateValue = {};
          let cashRowKeys = [];
          Object.keys(row).forEach((key) => {
            if (!DEFAULT_KEYS.includes(key)) {
              cashRowDateValue[key] = 0;
              cashRowKeys.push(key);
            }
          });
          cashRowKeys = [...cashRowKeys]
            .sort((a, b) => {
              return moment(a).month() - moment(b).month();
            })
            .sort((a, b) => {
              return moment(a).year() - moment(b).year();
            });
          cashRowKeys.forEach((key, index) => {
            if (row.rowType === ROW_TYPE.ACCOUNT) {
              row.cashTiming.forEach((cashPercent, subIndex) => {
                if (cashRowKeys[index + subIndex]) {
                  cashRowDateValue[cashRowKeys[index + subIndex]] +=
                    (cashPercent / 100) * (row[key]["value"] || 0);
                }
              });
            }
          });
          cashRowKeys.forEach((key) => {
            if (cashRowIndex !== -1) {
              clone["cash"]["rows"][cashRowIndex]["visible"] = row?.showInCash;
              if (clone["cash"]["rows"][cashRowIndex][key] && row?.showInCash) {
                clone["cash"]["rows"][cashRowIndex][key]["value"] =
                  cashRowDateValue[key];
              }
            }
          });
        }
        let superannuationExpenseIndex = clone?.["people"]?.[
          "payrollInformation"
        ]?.["rows"].findIndex(
          (val) => val?.metric === "Superannuation Payment"
        );
        let superpayableIndex = clone?.["cash"]?.["rows"]?.findIndex(
          (val) => val?.metricName === "Super Payable"
        );
        Object.keys(
          clone?.["cash"]?.["rows"]?.[superpayableIndex] || {}
        ).forEach((key) => {
          if (
            !DEFAULT_KEYS.includes(key) &&
            !clone?.["cash"]?.["rows"]?.[superpayableIndex]?.[key]?.["disabled"]
          ) {
            clone["cash"]["rows"][superpayableIndex][key]["value"] =
              clone?.["people"]?.["payrollInformation"]?.["rows"]?.[
                superannuationExpenseIndex
              ]?.[key] || 0;
          }
        });
      });
    });
    let { cashClone, expenseClone } = calculateSumCashRows(
      clone["cash"]["rows"],
      clone["revenue"]["rows"],
      clone["expense"]["rows"],
      clone["people"]["rows"],
      clone["people"]["payrollInformation"]["rows"],
      currentEntity,
      tabCalculationType
    );
    clone["cash"]["rows"] = cashClone;
    if (
      tabCalculationType === tabType.all ||
      tabCalculationType === tabType.people
    ) {
      let valueRevertExpenseRows = spreadsheet.people.rows.filter(
        (val) => !currentTabRowData.map((val) => val.id).includes(val.id)
      );
      valueRevertExpenseRows = valueRevertExpenseRows.map(
        (val) => val?.mapping?.value
      );
      valueRevertExpenseRows.forEach((id) => {
        let checkMultipleEntry = currentTabRowData
          .map((val) => val?.mapping?.value)
          .includes(id);
        if (!checkMultipleEntry) {
          let expenseIndex = expenseClone.findIndex((val) => val.id === id);
          let expenseRow = _.cloneDeep(expenseClone[expenseIndex]);
          let revaluatedValues = handleValueCalculationsForecastIncluded(
            expenseRow?.metricType,
            expenseClone,
            spreadsheet?.expense?.columns,
            expenseClone,
            expenseRow,
            "cellchange",
            false,
            null,
            false,
            "rows",
            null,
            durationData
          );
          expenseClone = revaluatedValues;
        }
      });
      clone["expense"]["rows"] = handleSumRowsHelper(
        expenseClone,
        expenseClone
      );
      if (clone["people"]["rows"].length === 0) {
        let mappingId = currentEntity?.employees?.defaultPayrollMappingType?.id;
        let resetIndexCash = clone?.["cash"]?.["rows"]?.findIndex(
          (val) => val?.refId === mappingId
        );
        let resetIndexExpense = clone?.["expense"]?.["rows"]?.findIndex(
          (val) => val?.id === mappingId
        );
        Object.keys(clone?.["cash"]?.["rows"]?.[0]).forEach((key) => {
          if (
            !DEFAULT_KEYS.includes(key) &&
            !clone?.["cash"]?.["rows"]?.[0]?.[key]?.["disabled"] &&
            resetIndexCash !== -1
          ) {
            clone["cash"]["rows"][resetIndexCash][key]["value"] = _.cloneDeep(
              clone?.["expense"]?.["rows"]?.[resetIndexExpense]?.[key]?.[
                "value"
              ]
            );
          }
        });
      }
    }
  }
  if (refreshNecessary) dispatch(refreshData({ spreadsheet: clone }));
  return { spreadsheet: clone, tabData: clone[currentTabType].rows };
};

const getSummedPaygValue = (key, keyMonths, currentMonth, paygValues) => {
  let summedPayg = 0;
  keyMonths?.[currentMonth].forEach((value) => {
    summedPayg +=
      paygValues?.[getKeyFromDate(moment(key).subtract(value, "month"))]?.[
        "value"
      ] || 0;
  });
  return summedPayg;
};

export const peoplePayrollCalculation = (
  payrollRows,
  currentEntity,
  durationEnd,
  value,
  peopleData
) => {
  const clone = _.cloneDeep(payrollRows);
  const employeesSettings = currentEntity?.employees;
  let startingDate = moment(employeesSettings?.nextPayrollPaymentDate).subtract(
    104,
    "weeks"
  );
  let payrollDateTemp = startingDate.clone();
  let paymentDates = [];
  let paymentCount = {};
  if (employeesSettings.payrollTiming !== "Monthly") {
    let additionValue = employeesSettings?.payrollTiming === "Weekly" ? 7 : 14;
    while (
      payrollDateTemp.month() < 6 ||
      payrollDateTemp.year() !== startingDate.year() + 7
    ) {
      payrollDateTemp.add(additionValue, "days");
      paymentDates.push(payrollDateTemp.format("YYYY-MM-DD"));
    }
    paymentDates.pop();
    paymentDates.forEach((date) => {
      let dateValue = moment(date);
      paymentCount[
        MONTH_MAP[dateValue.month() + 1] + dateValue.year()
      ] = paymentDates.filter((val) => {
        let dateVal = moment(val);
        return (
          dateValue.month() === dateVal.month() &&
          dateValue.year() === dateVal.year()
        );
      }).length;
    });
  }
  //initialization since the calculation is made real time for adopting any new change
  let superExpense = 0;
  let superExpenseCash = 0;
  let cashRow = {};
  payrollRows.forEach((row, index) => {
    const keys = Object.keys(row);
    keys.forEach((key) => {
      if (!DEFAULT_KEYS.includes(key)) {
        let currentDate = dayjs(key);
        let applicableRate = employeesSettings?.superannuationRate;
        let totalExpense = 0;
        let totalExpenseCash = 0;

        value.forEach((employee) => {
          const timingRange = getTimingRange(
            employee?.startDate,
            dayjs(employee?.endDate ?? durationEnd).format("YYYY-MM")
          );

          if (timingRange.includes(key)) {
            totalExpense =
              totalExpense +
              (employee?.[key]?.value - (employee?.[key]?.bonus ?? 0));
            totalExpenseCash =
              totalExpenseCash +
              ((employee?.[key]?.["cashValue"] ?? 0) -
                (employee?.[key]?.bonus ?? 0));
          }
        });
        switch (row?.metric) {
          case "Total Headcount":
            let count = 0;
            value.forEach((employee) => {
              const timingRange = getTimingRange(
                employee?.startDate,
                dayjs(employee?.endDate ?? durationEnd).format("YYYY-MM")
              );

              if (timingRange?.includes(key)) {
                count++;
              }
            });
            clone[index][key] = count;
            break;

          case "Total Wage":
            const monthlyPay = peopleData?.rows
              .map((val) => val?.[key]?.["value"] || 0)
              .reduce((a, b) => parseFloat(a + b), 0)
              .toFixed(2);
            clone[index][key] = monthlyPay;
            break;

          case "Pays in month":
            clone[index][key] =
              employeesSettings?.payrollTiming !== "Monthly"
                ? paymentCount?.[key] || 0
                : 1;
            break;

          case "Superannuation rate":
          case "Superannuation Expense":
            if (applicableRate === "Statutory Rate") {
              if (currentDate.isBefore(dayjs("July 1, 2024"))) {
                applicableRate = 11;
              } else if (
                currentDate.isAfter(dayjs("June 30, 2024")) &&
                currentDate.isBefore(dayjs("July 1, 2025"))
              ) {
                applicableRate = 11.5;
              } else if (currentDate.isAfter(dayjs("June 30, 2025"))) {
                applicableRate = 12;
              }
            }
            if (row.metric === "Superannuation rate") {
              clone[index][key] = applicableRate;
            } else {
              superExpense = totalExpense * (applicableRate / 100);
              superExpenseCash = totalExpenseCash * (applicableRate / 100);
              clone[index][key] = parseFloat(superExpense?.toFixed(2));
              cashRow[key] = parseFloat(superExpenseCash?.toFixed(2));
            }
            break;

          case "Superannuation Payment":
            let paymentMonthFlag = 1;
            const paymentMonths = employeesSettings?.paymentMonths;

            if (employeesSettings?.superannuationTiming === "Quarterly") {
              const currentMonth = dayjs(key).format("MMMM");
              let checkMonthArray =
                paymentMonths[0] === "Month after end of quarter"
                  ? ["January", "April", "July", "October"]
                  : paymentMonths[0] === "Last month of quarter"
                  ? ["March", "June", "September", "December"]
                  : ["January", "April", "June", "October"];
              if (checkMonthArray.includes(currentMonth)) {
                paymentMonthFlag = 1;
              } else {
                paymentMonthFlag = 0;
              }
              let dates;
              let superExpenseSum = 0;
              let threeMonthsAgo;

              if (paymentMonthFlag === 1) {
                switch (paymentMonths[0]) {
                  case "Month after end of quarter":
                    threeMonthsAgo = currentDate
                      .subtract(3, "month")
                      .format("YYYY-MM");
                    dates = getTimingRange(
                      threeMonthsAgo,
                      currentDate.subtract(1, "month").format("YYYY-MM")
                    );
                    dates.forEach((date) => {
                      superExpenseSum += cashRow?.[date] ?? 0;
                    });
                    clone[index][key] = superExpenseSum?.toFixed(2);
                    break;
                  case "Last month of quarter":
                    threeMonthsAgo = currentDate
                      .subtract(2, "month")
                      .format("YYYY-MM");
                    dates = getTimingRange(
                      threeMonthsAgo,
                      currentDate.format("YYYY-MM")
                    );
                    dates.forEach((date) => {
                      superExpenseSum += cashRow?.[date] ?? 0;
                    });
                    clone[index][key] = superExpenseSum?.toFixed(2);
                    break;
                  case "Month after end of quarter, same month for last month of FY":
                    threeMonthsAgo = currentDate
                      .subtract(currentDate.month() === 5 ? 2 : 3, "month")
                      .format("YYYY-MM");
                    dates = getTimingRange(
                      threeMonthsAgo,
                      currentDate
                        .subtract(currentDate.month() === 5 ? 0 : 1, "month")
                        .format("YYYY-MM")
                    );
                    dates.forEach((date) => {
                      superExpenseSum += cashRow?.[date] ?? 0;
                    });
                    clone[index][key] = superExpenseSum.toFixed(2);
                    break;
                  default:
                    break;
                }
              } else {
                clone[index][key] = 0;
              }
            } else {
              switch (paymentMonths[0]) {
                case "Same month as expense":
                  clone[index][key] = parseFloat(cashRow?.[key]);
                  break;
                case "Month after expense":
                case "Month after expense, same month for last month of FY":
                  const previousMonth = dayjs(key).subtract(1, "M");
                  const prevMonthKey =
                    dayjs(previousMonth).format("MMM").toLowerCase() +
                    dayjs(previousMonth).year().toString();
                  let value = cashRow?.[prevMonthKey] || 0;
                  if (dayjs(key).format("MMM").toLowerCase() === "jul") {
                    value = 0;
                  }
                  if (dayjs(key).format("MMM").toLowerCase() === "jun") {
                    let tMinusTwoMonthKey =
                      dayjs(previousMonth)
                        .subtract(1, "M")
                        .format("MMM")
                        .toLowerCase() +
                      dayjs(previousMonth).subtract(1, "M").year().toString();
                    value =
                      (cashRow?.[prevMonthKey] || 0) +
                      (cashRow?.[tMinusTwoMonthKey] || 0);
                  }
                  clone[index][key] = value;
                  break;

                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      }
    });
  });
  return clone;
};

export const calculateMonthlySalary = (
  updatedHistory,
  overrideManual,
  modifiedSideNavData,
  currentEntity,
  durationEnd,
  sideNavData,
  endDate,
  minDate,
  payrollRows,
  paymentType
) => {
  let payrollType = currentEntity?.employees?.payrollTiming || "Monthly";
  const updatedSideNavData = _.cloneDeep(modifiedSideNavData);
  const allKeys = getTimingRange(
    updatedSideNavData?.startDate,
    dayjs(updatedSideNavData?.endDate ?? durationEnd).format("YYYY-MM")
  );
  let annualSalaries = updatedSideNavData?.annualSalaries || {};
  if ((updatedSideNavData?.changeHistory || [])?.length === 0) {
    let annualSalary =
      updatedSideNavData.paymentType === "hourly"
        ? updatedSideNavData?.initialValue?.weeklyHours *
          updatedSideNavData?.initialValue?.hourlyRate *
          52
        : updatedSideNavData?.initialValue?.annualSalary;
    updatedSideNavData["initialValue"]["annualSalary"] = annualSalary;
    Object.keys(annualSalaries)?.forEach((key) => {
      annualSalaries[key] = annualSalary;
    });
    updatedSideNavData.annualSalaries = annualSalaries;
  }
  //initializing default and 0 values for new calculations from first looking into each history and other changes one by one
  allKeys.forEach((key) => {
    if (!updatedSideNavData?.[key]) {
      updatedSideNavData[key] = {
        disabled: true,
      };
    }
    if (!(!overrideManual && updatedSideNavData?.[key]?.manualInput)) {
      updatedSideNavData[key]["value"] =
        payrollType === "Monthly" || paymentType
          ? updatedSideNavData?.initialValue?.annualSalary / 12
          : (updatedSideNavData?.annualSalaries?.[key] /
              (payrollType === "Weekly" ? 52 : 26)) *
            payrollRows?.[2]?.[key];
      updatedSideNavData[key]["cashValue"] =
        (updatedSideNavData?.annualSalaries?.[key] /
          (payrollType === "Weekly"
            ? 52
            : payrollType === "Monthly"
            ? 12
            : 26)) *
        (payrollType === "Monthly" ? 1 : payrollRows?.[2]?.[key]);
      updatedSideNavData[key].manualInput = false;
    }
    updatedSideNavData[key]["bonus"] = 0;
    updatedSideNavData[key]["hourlyRate"] =
      updatedSideNavData?.initialValue?.hourlyRate;
    updatedSideNavData[key]["weeklyHours"] =
      updatedSideNavData?.initialValue?.weeklyHours;
    updatedSideNavData["fte"] =
      updatedSideNavData?.initialValue?.weeklyHours /
      (currentEntity?.employees?.standardWeeklyHours ?? 1);
    if (updatedSideNavData.paymentType !== "salary") {
      updatedSideNavData["hourlyRate"] =
        updatedSideNavData?.initialValue?.hourlyRate;
    }
    updatedSideNavData["annualSalary"] =
      updatedSideNavData?.initialValue?.annualSalary;
  });

  const salaryCalculation = updatedHistory?.map((history) => ({
    ...history,
    keys: getTimingRange(
      dayjs(history.date).format("YYYY-MM"),
      dayjs(sideNavData?.endDate ?? endDate).format("YYYY-MM")
    ),
  }));

  let annualChangeDateRange = getTimingRange(
    dayjs(sideNavData?.startDate ?? minDate).format("YYYY-MM"),
    dayjs(sideNavData?.endDate ?? endDate).format("YYYY-MM")
  );
  annualChangeDateRange.forEach((dateValue) => {
    updatedSideNavData.annualSalaries[dateValue] =
      updatedSideNavData?.initialValue?.annualSalary;
  });

  salaryCalculation &&
    [
      ...salaryCalculation.sort((a, b) => {
        return moment(a?.date).diff(moment(b?.date));
      }),
    ].forEach((calculation) => {
      const {
        type,
        keys,
        value,
        oldValue,
        changeVariant,
        changeType,
        date,
      } = calculation;
      if (type === "payRise") {
        const factor = changeVariant === "Increase" ? 1 : -1;
        keys.forEach((key) => {
          if (!overrideManual) {
            if (updatedSideNavData?.[key]?.manualInput) {
              return;
            }
          } else {
            updatedSideNavData[key].manualInput = false;
          }

          const newValue =
            oldValue +
            (changeType === "amount"
              ? (value / 12) * factor
              : ((value / 100) * (oldValue * 12) * factor) / 12);
          updatedSideNavData[key].value = newValue;
          updatedSideNavData.annualSalary = newValue * 12;
        });
      } else if (type === "hourlyRate") {
        keys.forEach((key) => {
          if (!overrideManual) {
            if (updatedSideNavData?.[key]?.manualInput) return;
          } else {
            updatedSideNavData[key].manualInput = false;
          }
          const annualSalary =
            value *
            (updatedSideNavData?.[key]?.weeklyHours ??
              updatedSideNavData?.initialValue?.weeklyHours) *
            52;
          let annualChangeDateRange = getTimingRange(
            date,
            dayjs(sideNavData?.endDate ?? endDate).format("YYYY-MM")
          );
          annualChangeDateRange.forEach((dateValue) => {
            updatedSideNavData.annualSalaries[dateValue] = annualSalary;
          });
          const newValue =
            payrollType === "Monthly" || paymentType
              ? annualSalary / 12
              : (updatedSideNavData?.annualSalaries?.[key] /
                  (payrollType === "Weekly" ? 52 : 26)) *
                payrollRows?.[2]?.[key];
          updatedSideNavData[key]["cashValue"] =
            (updatedSideNavData?.annualSalaries?.[key] /
              (payrollType === "Weekly"
                ? 52
                : payrollType === "Monthly"
                ? 12
                : 26)) *
            (payrollType === "Monthly" ? 1 : payrollRows?.[2]?.[key]);
          updatedSideNavData[key].value = newValue;
          updatedSideNavData[key].hourlyRate = value;
          updatedSideNavData.hourlyRate = value;
          updatedSideNavData.annualSalary = annualSalary;
        });
      } else if (type === "weeklyHours") {
        if (updatedSideNavData?.paymentType !== "salary") {
          keys.forEach((key) => {
            if (!overrideManual) {
              if (updatedSideNavData?.[key]?.manualInput) return;
            } else {
              updatedSideNavData[key].manualInput = false;
            }
            updatedSideNavData.fte =
              value / currentEntity?.employees?.standardWeeklyHours;
            const annualSalary =
              value *
              (updatedSideNavData?.[key]?.hourlyRate ??
                updatedSideNavData?.initialValue?.hourlyRate) *
              52;

            let annualChangeDateRange = getTimingRange(
              date,
              dayjs(sideNavData?.endDate ?? endDate).format("YYYY-MM")
            );
            annualChangeDateRange.forEach((dateValue) => {
              updatedSideNavData.annualSalaries[dateValue] = annualSalary;
            });
            const newValue =
              payrollType === "Monthly" || paymentType
                ? annualSalary / 12
                : (updatedSideNavData?.annualSalaries?.[key] /
                    (payrollType === "Weekly" ? 52 : 26)) *
                  payrollRows[2][key];
            updatedSideNavData[key]["cashValue"] =
              (updatedSideNavData?.annualSalaries?.[key] /
                (payrollType === "Weekly"
                  ? 52
                  : payrollType === "Monthly"
                  ? 12
                  : 26)) *
              (payrollType === "Monthly" ? 1 : payrollRows?.[2]?.[key]);
            updatedSideNavData.weeklyHours = value;
            updatedSideNavData[key].value = newValue;
            updatedSideNavData[key].weeklyHours = value;
            updatedSideNavData.annualSalary = annualSalary;
          });
        } else {
          keys.forEach((key) => {
            updatedSideNavData.fte =
              value / currentEntity?.employees?.standardWeeklyHours;
            updatedSideNavData.weeklyHours = value;
            updatedSideNavData[key].weeklyHours = value;
          });
        }
      }
    });

  salaryCalculation &&
    salaryCalculation.forEach((calculation) => {
      const { type, date, value } = calculation;
      const key =
        dayjs(date).format("MMM").toLowerCase() + dayjs(date).year().toString();
      if (type === "bonus") {
        if (!overrideManual) {
          if (updatedSideNavData[key].manualInput) return;
        } else {
          updatedSideNavData[key].manualInput = false;
        }
        let bonusValue =
          calculation.changeType === "percent"
            ? ((updatedSideNavData?.[key]?.value -
                updatedSideNavData?.[key]?.bonus) *
                value) /
              100
            : value;
        updatedSideNavData[key].value += bonusValue;

        updatedSideNavData[key].bonus =
          parseFloat(updatedSideNavData?.[key]?.bonus) || 0;
        updatedSideNavData[key].bonus += bonusValue;
      }
    });

  return { updatedHistory, updatedSideNavData };
};

let getpaygCalculatedValue = (cashValue, paygA, paygB, stslA) => {
  return cashValue * paygA - paygB + (cashValue * stslA) / 100;
};
let getPaygConstantValues = (weeklyValue, taxThresholdValue, stslValue) => {
  let taxThresholdKeys = Object.keys(taxThresholdValue);
  let taxThresholdRange = taxThresholdKeys.map((val) => {
    if (val.length > 4) {
      return parseFloat(val.substring(0, 4)) + 1;
    } else {
      return parseFloat(val);
    }
  });
  let taxRange = 0;
  for (let i = 0; i < taxThresholdRange?.length; i++) {
    if (weeklyValue < taxThresholdRange[i]) {
      taxRange = i;
      break;
    } else {
      taxRange = taxThresholdRange.length - 1;
    }
  }
  let stslTaxRange = 0;
  let stslKeys = Object.keys(stslValue || {}).sort(
    (a, b) => parseFloat(a) - parseFloat(b)
  );
  if (stslValue) {
    let stslTaxThresholdRange = stslKeys.map((val) => {
      return parseFloat(val);
    });
    for (let i = 0; i < stslTaxThresholdRange.length; i++) {
      if (
        weeklyValue > stslTaxThresholdRange?.[i] &&
        weeklyValue < stslTaxThresholdRange?.[i + 1] &&
        stslTaxThresholdRange?.[i + 1]
      ) {
        stslTaxRange = i;
        break;
      } else {
        stslTaxRange = stslTaxThresholdRange.length - 1;
      }
    }
  }
  return {
    paygA: parseFloat(taxThresholdValue?.[taxThresholdKeys?.[taxRange]]?.["a"]),
    paygB: parseFloat(taxThresholdValue?.[taxThresholdKeys?.[taxRange]]?.["b"]),
    stslA: parseFloat(stslValue?.[stslKeys?.[stslTaxRange]]?.["a"] || 0),
  };
};

export const calculateSumCashRows = (
  rows,
  revenueRows,
  expenseRows,
  peopleRows,
  payrollRows,
  currentEntity,
  tabCalculationType
) => {
  let expenseClone = _.cloneDeep(expenseRows);
  let mappedExpenses = peopleRows.map((row) => row?.mapping?.value);
  let keys = Object.keys(rows[0]);
  let cloneRows = _.cloneDeep(rows);
  cloneRows.forEach((val, index) => {
    if (
      val?.rowType === ROW_TYPE.ACCOUNT &&
      val?.subParentId?.includes("other_items")
    ) {
      keys.forEach((key) => {
        if (
          !DEFAULT_KEYS.includes(key) &&
          !cloneRows?.[index]?.[key]?.["manualInput"]
        ) {
          cloneRows[index][key]["value"] =
            cloneRows?.[index]?.["initialValue"] || 0;
        }
      });
    }
  });
  let summedArrayClone = _.cloneDeep(cloneRows);

  if (
    tabCalculationType === tabType.people ||
    tabCalculationType === tabType.expense ||
    tabCalculationType === tabType.all
  ) {
    //logic for payg rates
    let employeePaygRates = _.cloneDeep(peopleRows);
    let employeeCashRates = _.cloneDeep(peopleRows);
    let paygValues = {};
    Object.keys(cloneRows[0]).forEach((key) => {
      if (
        !DEFAULT_KEYS.includes(key) &&
        cloneRows?.[0]?.[key]?.["disabled"] === false
      )
        paygValues[key] = { value: 0, manualInput: false, disabled: false };
    });
    let multiplicationValue =
      currentEntity?.employees?.payrollTiming === "Weekly"
        ? 1
        : currentEntity?.employees?.payrollTiming === "Monthly"
        ? 4.33
        : 2;
    let payInMonthRow = payrollRows?.find(
      (val) => val.metric === "Pays in month"
    );
    peopleRows.forEach((peopleRow, index) => {
      let taxThresholdValue = peopleRow?.taxFreeThresholdValue
        ? paygRates?.[0]?.value
        : paygRates?.[1]?.value;
      let stslValue = peopleRow?.stslApplied
        ? peopleRow?.taxFreeThresholdValue
          ? paygRates?.[3]?.value
          : paygRates?.[2]?.value
        : null;
      let latestAnnualSalary = null;
      let latestWeeklyValue = null;
      let latestPaygA = null;
      let latestPaygB = null;
      let latestStslA = null;
      Object.keys(peopleRow).forEach((key) => {
        if (
          !DEFAULT_KEYS.includes(key) &&
          peopleRow?.[key]?.["disabled"] === false
        ) {
          if (
            latestAnnualSalary === null ||
            latestWeeklyValue === null ||
            latestAnnualSalary !== peopleRow?.annualSalaries?.[key]
          ) {
            let weeklyValue = peopleRow?.annualSalaries?.[key] / 52;
            let { paygA, paygB, stslA } = getPaygConstantValues(
              weeklyValue,
              taxThresholdValue,
              stslValue
            );
            latestPaygA = paygA;
            latestPaygB = paygB;
            latestStslA = stslA;
            latestAnnualSalary = peopleRow?.annualSalaries?.[key];
            latestWeeklyValue = weeklyValue;
          }
          let paygCalculated = getpaygCalculatedValue(
            latestWeeklyValue,
            latestPaygA,
            latestPaygB,
            latestStslA
          );

          paygValues[key]["value"] +=
            paygCalculated * multiplicationValue * payInMonthRow?.[key];
          employeeCashRates[index][key] = {};
          employeePaygRates[index][key] = {};
          employeeCashRates[index][key]["value"] =
            paygCalculated * multiplicationValue * payInMonthRow?.[key];
          employeePaygRates[index][key]["value"] =
            paygCalculated * multiplicationValue * payInMonthRow?.[key];
        }
      });
    });

    let paygPaymentTiming = currentEntity?.employees?.paygPaymentTiming;

    let paygOnWagesRowIndex = summedArrayClone.findIndex(
      (val) => val?.metricName === "PAYG on wages"
    );
    let prevMonthsTaxAgent = [2, 3, 4];
    let prevMonthsClientLodges = [1, 2, 3];
    Object.keys(paygValues).forEach((key) => {
      let currentMonth = moment(key).month() + 1;
      if (currentEntity?.paygOnWages === "Same time as wages") {
        summedArrayClone[paygOnWagesRowIndex][key]["value"] =
          paygValues?.[key]?.["value"] || 0;
        summedArrayClone[paygOnWagesRowIndex][key]["manualInput"] = false;
      } else if (currentEntity?.employees?.paygOnWages === "Monthly") {
        if (paygPaymentTiming === "Tax agent extension") {
          let keyMonths = {
            2: [1, 2],
            3: [1],
            5: [1, 2],
            6: [1],
            8: [1, 2],
            9: [1],
            11: [1, 2],
            12: [1],
          };
          if (Object?.keys(keyMonths)?.includes(currentMonth?.toString())) {
            summedArrayClone[paygOnWagesRowIndex][key][
              "value"
            ] = getSummedPaygValue(key, keyMonths, currentMonth, paygValues);
          } else {
            summedArrayClone[paygOnWagesRowIndex][key]["value"] = 0;
            summedArrayClone[paygOnWagesRowIndex][key]["manualInput"] = false;
          }
        } else {
          let keyMonths = {
            2: [1, 2],
            3: [1],
            4: [1],
            5: [1],
            6: [1],
            7: [1],
            8: [1],
            9: [1],
            10: [1],
            11: [1],
            12: [1],
          };
          if (Object?.keys(keyMonths)?.includes(currentMonth?.toString())) {
            summedArrayClone[paygOnWagesRowIndex][key][
              "value"
            ] = getSummedPaygValue(key, keyMonths, currentMonth, paygValues);
          } else {
            summedArrayClone[paygOnWagesRowIndex][key]["value"] = 0;
            summedArrayClone[paygOnWagesRowIndex][key]["manualInput"] = false;
          }
        }
      } else {
        if (paygPaymentTiming === "Tax agent extension") {
          let keyMonths = {
            2: prevMonthsTaxAgent,
            5: prevMonthsTaxAgent,
            8: prevMonthsTaxAgent,
            11: prevMonthsTaxAgent,
          };
          if (Object?.keys(keyMonths)?.includes(currentMonth?.toString())) {
            summedArrayClone[paygOnWagesRowIndex][key][
              "value"
            ] = getSummedPaygValue(key, keyMonths, currentMonth, paygValues);
          } else {
            summedArrayClone[paygOnWagesRowIndex][key]["value"] = 0;
            summedArrayClone[paygOnWagesRowIndex][key]["manualInput"] = false;
          }
        } else {
          let keyMonths = {
            2: prevMonthsClientLodges,
            4: prevMonthsClientLodges,
            7: prevMonthsClientLodges,
            10: prevMonthsClientLodges,
          };
          if (Object?.keys(keyMonths)?.includes(currentMonth?.toString())) {
            summedArrayClone[paygOnWagesRowIndex][key][
              "value"
            ] = getSummedPaygValue(key, keyMonths, currentMonth, paygValues);
          } else {
            summedArrayClone[paygOnWagesRowIndex][key]["value"] = 0;
            summedArrayClone[paygOnWagesRowIndex][key]["manualInput"] = false;
          }
        }
      }
    });

    // push the payg gross to expense and net to the respective mapped value
    let netPaygRates = new Array(peopleRows?.length).fill({});

    peopleRows.forEach((row, index) => {
      let paygRate = employeePaygRates?.find((val) => val?.id === row?.id);
      netPaygRates[index] = _.cloneDeep(paygRate);
      Object.keys(row).forEach((key) => {
        if (
          !DEFAULT_KEYS.includes(key) &&
          typeof netPaygRates?.[index]?.[key] === "object"
        ) {
          netPaygRates[index][key]["value"] =
            row[key]["cashValue"] - paygRate[key]["value"];
        }
      });
    });
    let uniquePaygIds = [
      ...new Set(netPaygRates.map((val) => val?.mapping?.value)),
    ];
    uniquePaygIds.forEach((id) => {
      let paygRates = netPaygRates.filter((val) => val?.mapping?.value === id);
      let grossPaygRates = peopleRows.filter(
        (val) => val?.mapping?.value === id
      );
      let summedPaygRate = _.cloneDeep(
        paygRates.find((val) => val?.mapping?.value === id)
      );
      let summedGrossPaygRate = _.cloneDeep(
        peopleRows.find((val) => val?.mapping?.value === id)
      );
      Object.keys(summedPaygRate).forEach((key) => {
        if (
          !DEFAULT_KEYS.includes(key) &&
          typeof summedPaygRate?.[key] === "object"
        ) {
          summedPaygRate[key]["value"] = paygRates.reduce(
            (a, b) => a + b?.[key]?.["value"] || 0,
            0
          );
          summedGrossPaygRate[key]["value"] = grossPaygRates.reduce(
            (a, b) => a + b?.[key]?.["value"] || 0,
            0
          );
        }
      });
      let summedArrayIndex = summedArrayClone.findIndex(
        (val) => val?.refId === summedPaygRate?.mapping?.value
      );
      let expenseIndex = expenseRows.findIndex((val) => val?.id === id);
      let peopleRowIds = peopleRows.map((val) => val?.mapping?.value);

      Object.keys(summedPaygRate).forEach((key) => {
        if (
          !DEFAULT_KEYS.includes(key) &&
          typeof summedArrayClone?.[summedArrayIndex]?.[key] === "object"
        ) {
          summedArrayClone[summedArrayIndex][key]["value"] =
            summedPaygRate?.[key]?.["value"] || 0;
          if (peopleRowIds?.includes(expenseClone?.[expenseIndex]?.id)) {
            expenseClone[expenseIndex][key]["value"] =
              summedGrossPaygRate?.[key]?.["value"] || 0;
            expenseClone[expenseIndex][key]["manualInput"] = false;
          }
        }
      });
    });
  }

  summedArrayClone = _.cloneDeep(summedArrayClone).map((row) => {
    if (
      row?.rowType === ROW_TYPE.CUSTOM_METRIC ||
      row?.rowType === ROW_TYPE.ACCOUNT_GROUP
    ) {
      let clone = _.cloneDeep(row);
      let childrenRows = cloneRows.filter((val) => {
        return row?.rowType === ROW_TYPE.CUSTOM_METRIC
          ? val?.subParentId === row?.id && !val?.groupParentId
          : val?.groupParentId === row?.id &&
              row?.rowType === ROW_TYPE.ACCOUNT_GROUP;
      });
      let rowKeys = Object.keys(row);
      rowKeys.forEach((key) => {
        if (!DEFAULT_KEYS.includes(key)) {
          clone[key]["value"] = childrenRows?.reduce(
            (a, b) => a + b?.[key]?.["value"],
            0
          );
        }
      });
      return clone;
    } else {
      return row;
    }
  });

  let gstRows = ["GST on Income", "GST on expenses"];
  if (tabCalculationType === tabType.revenue) {
    gstRows.splice(1, 1);
  } else if (tabCalculationType === tabType.expense) {
    gstRows.splice(0, 1);
  }
  gstRows.forEach((rowName, index) => {
    Object.keys(revenueRows[0]).forEach((key) => {
      if (
        !DEFAULT_KEYS.includes(key) &&
        !revenueRows?.[0]?.[key]?.["disabled"]
      ) {
        let filteredRowsData = (index === 0 ? revenueRows : expenseRows).reduce(
          (acc, row) => {
            if (
              row?.rowType === ROW_TYPE.ACCOUNT &&
              row?.gstApplicable &&
              !mappedExpenses?.includes(rows?.id)
            ) {
              acc.push(row.id);
            }
            return acc;
          },
          []
        );
        let cloneRows = _.cloneDeep(summedArrayClone).filter((val) =>
          filteredRowsData?.includes(val?.refId)
        );
        let sum = cloneRows.reduce(
          (a, b) =>
            a +
            parseInt(
              ((b?.[key]?.["value"] || 0) *
                parseInt(currentEntity?.gst?.rate || "10")) /
                100
            ),
          0
        );
        let valueIndex = summedArrayClone.findIndex(
          (val) => val.metricName === rowName
        );
        summedArrayClone[valueIndex][key]["value"] = sum;
      }
    });
  });

  let gstIncomeIndex = summedArrayClone.findIndex(
    (val) => val.metricName === "GST on Income"
  );
  let gstExpenseIndex = summedArrayClone.findIndex(
    (val) => val.metricName === "GST on expenses"
  );
  let basPaymentIndex = summedArrayClone.findIndex(
    (val) => val.metricName === "BAS payment / (refund)"
  );

  let gstIncomeRow = _.cloneDeep(summedArrayClone?.[gstIncomeIndex]);
  let gstExpenseRow = _.cloneDeep(summedArrayClone?.[gstExpenseIndex]);

  if (currentEntity?.gst?.basis === "Accrual") {
    gstRows.forEach((row, index) => {
      Object.keys(revenueRows[0]).forEach((key) => {
        if (
          !DEFAULT_KEYS.includes(key) &&
          !revenueRows?.[0]?.[key]?.["disabled"]
        ) {
          let filteredRowsData = (index === 0
            ? revenueRows
            : expenseRows
          ).filter(
            (row) => row?.rowType === ROW_TYPE.ACCOUNT && row?.gstApplicable
          );

          let sum = filteredRowsData.reduce(
            (a, b) =>
              a +
              parseInt(
                ((b?.[key]?.["value"] || 0) *
                  parseInt(currentEntity?.gst?.rate || "10")) /
                  100
              ),
            0
          );

          if (index === 0) {
            gstIncomeRow[key]["value"] = sum;
          } else {
            gstExpenseRow[key]["value"] = sum;
          }
        }
      });
    });
  }

  if (currentEntity?.gst?.period === "Monthly") {
    Object.keys(summedArrayClone?.[0]).forEach((key) => {
      let isTaxAgentLodged =
        currentEntity?.gst?.paymentDate !== "Client lodges";
      if (
        !DEFAULT_KEYS.includes(key) &&
        !summedArrayClone?.[0]?.[key]?.["disabled"]
      ) {
        if (isTaxAgentLodged && moment(key).month() === 1) {
          let prevMonthKey = getKeyFromDate(moment(key).subtract(1, "month"));
          let secondPrevMonthKey = getKeyFromDate(
            moment(key).subtract(2, "month")
          );
          summedArrayClone[basPaymentIndex][key]["value"] =
            (gstIncomeRow?.[prevMonthKey]?.["value"] || 0) -
            (gstExpenseRow?.[prevMonthKey]?.["value"] || 0) +
            ((gstIncomeRow?.[secondPrevMonthKey]?.["value"] || 0) -
              (gstExpenseRow?.[secondPrevMonthKey]?.["value"] || 0));
        } else if (isTaxAgentLodged && moment(key).month() === 0) {
          summedArrayClone[basPaymentIndex][key]["value"] = 0;
        } else {
          let prevMonthKey = getKeyFromDate(moment(key).subtract(1, "month"));
          summedArrayClone[basPaymentIndex][key]["value"] =
            (gstIncomeRow?.[prevMonthKey]?.["value"] || 0) -
            (gstExpenseRow?.[prevMonthKey]?.["value"] || 0);
        }
      }
    });
  } else {
    let prevMonthNormal = [1, 2, 3];
    let prevMonthGapped = [2, 3, 4];
    let paymentMonths =
      currentEntity?.gst?.paymentDate === "Client lodges"
        ? {
            2: prevMonthGapped,
            4: prevMonthNormal,
            7: prevMonthNormal,
            10: prevMonthNormal,
          }
        : {
            2: prevMonthGapped,
            5: prevMonthGapped,
            8: prevMonthGapped,
            11: prevMonthGapped,
          };

    Object.keys(summedArrayClone?.[0]).forEach((key) => {
      let monthKey = moment(key).month() + 1;
      if (
        !DEFAULT_KEYS.includes(key) &&
        !summedArrayClone?.[0]?.[key]?.["disabled"]
      ) {
        summedArrayClone[basPaymentIndex][key]["value"] = Object.keys(
          paymentMonths
        ).includes(monthKey.toString())
          ? paymentMonths[monthKey?.toString()].reduce((acc, val) => {
              let prevMonthKey = getKeyFromDate(
                moment(key).subtract(val, "month")
              );
              acc +=
                (gstIncomeRow?.[prevMonthKey]?.["value"] || 0) -
                (gstExpenseRow?.[prevMonthKey]?.["value"] || 0);
              return acc;
            }, 0)
          : 0;
      }
    });
  }

  summedArrayClone = _.cloneDeep(summedArrayClone).map((row) => {
    if (row.rowType === ROW_TYPE.SUB_TOTAL_ROW) {
      let clone = _.cloneDeep(row);
      let childrenRows = cloneRows.filter(
        (val) =>
          (row?.groupParentId
            ? val?.groupParentId === row?.groupParentId
            : val?.subParentId === row?.subParentId) &&
          val?.rowType !== ROW_TYPE.SUB_TOTAL_ROW &&
          val?.visible
      );
      let rowKeys = Object.keys(row);
      rowKeys.forEach((key) => {
        if (!DEFAULT_KEYS.includes(key)) {
          clone[key]["value"] = childrenRows.reduce(
            (a, b) => a + b?.[key]?.["value"],
            0
          );
        }
      });
      return clone;
    } else {
      return row;
    }
  });

  let finalTotalRows = ["Cash Inflows", "Cash Outflows", "Net Cash Flows"];
  finalTotalRows.forEach((rowName, index) => {
    if (index === 0) {
      let summableRows = summedArrayClone.filter(
        (val) =>
          (val?.rowType === ROW_TYPE.CUSTOM_METRIC &&
            val?.parentId?.includes("cash_inflows") &&
            !val?.groupParentId) ||
          val?.metricName === "GST on Income"
      );
      let cashInflowIndex = summedArrayClone.findIndex(
        (val) => val?.metricName === rowName
      );
      Object.keys(summedArrayClone[0]).forEach((key) => {
        if (
          !DEFAULT_KEYS.includes(key) &&
          !summedArrayClone?.[0]?.[key]?.["disabled"]
        ) {
          summedArrayClone[cashInflowIndex][key]["value"] = summableRows.reduce(
            (a, b) => a + parseFloat(b?.[key]?.["value"] || 0),
            0
          );
        }
      });
    } else if (index === 1) {
      let subValuesList = [
        "Super Payable",
        "PAYG on wages",
        "GST on expenses",
        "Income tax instalment",
        "Income tax payment/refund",
        "BAS payment / (refund)",
        "FBT tax instalment",
      ];
      let taxesAndSuperIndex = summedArrayClone.findIndex(
        (val) => val.metricName === "Taxes and Super"
      );

      let summableRowsTaxesAndSuper = summedArrayClone.filter((val) =>
        subValuesList.includes(val?.metricName)
      );
      let summableRowsTaxesAndSuperAccounts = summedArrayClone.reduce(
        (acc, val) => {
          if (val?.parentId?.includes("taxes_and_super") && val.refId !== "") {
            acc.push(val.refId);
          }
          return acc;
        },
        []
      );
      summableRowsTaxesAndSuperAccounts.forEach((val) => {
        let index = summedArrayClone.findIndex((row) => row.refId === val);
        let expenseRow = expenseRows.find((expRow) => {
          return expRow.id === val;
        });
        Object.keys(summedArrayClone[index]).forEach((key) => {
          if (
            !DEFAULT_KEYS.includes(key) &&
            !summedArrayClone?.[index]?.[key]?.["disabled"]
          ) {
            summedArrayClone[index][key]["value"] =
              expenseRow?.[key]?.["value"] || 0;
          }
        });
      });
      let summableRowsTaxesAndSuperFromExpense = expenseRows.filter((val) => {
        return summableRowsTaxesAndSuperAccounts.includes(val.id);
      });
      let summableRows = summedArrayClone.filter(
        (val) =>
          (val?.rowType === ROW_TYPE.CUSTOM_METRIC &&
            val?.parentId?.includes("cash_outflows") &&
            !val?.groupParentId) ||
          subValuesList?.includes(val?.metricName)
      );
      let cashOutflowIndex = summedArrayClone.findIndex(
        (val) => val?.metricName === rowName
      );
      Object.keys(summedArrayClone[0]).forEach((key) => {
        if (
          !DEFAULT_KEYS.includes(key) &&
          !summedArrayClone?.[0]?.[key]?.["disabled"]
        ) {
          summedArrayClone[cashOutflowIndex][key][
            "value"
          ] = summableRows.reduce(
            (a, b) => a + parseFloat(b?.[key]?.["value"] || 0),
            0
          );
          summedArrayClone[taxesAndSuperIndex][key]["value"] = [
            ...summableRowsTaxesAndSuper,
            ...summableRowsTaxesAndSuperFromExpense,
          ].reduce((a, b) => a + parseFloat(b?.[key]?.["value"] || 0), 0);
        }
      });
    } else {
      let inflowIndex = summedArrayClone.findIndex(
        (val) => val?.metricName === finalTotalRows?.[0]
      );
      let outflowIndex = summedArrayClone.findIndex(
        (val) => val?.metricName === finalTotalRows?.[1]
      );
      let netCashFlowIndex = summedArrayClone.findIndex(
        (val) => val?.metricName === rowName
      );
      Object.keys(summedArrayClone[0]).forEach((key) => {
        if (
          !DEFAULT_KEYS.includes(key) &&
          !summedArrayClone?.[0]?.[key]?.["disabled"]
        ) {
          summedArrayClone[netCashFlowIndex][key]["value"] =
            parseFloat(summedArrayClone?.[inflowIndex]?.[key]?.["value"] || 0) -
            parseFloat(summedArrayClone?.[outflowIndex]?.[key]?.["value"] || 0);
        }
      });
    }
  });

  let openingBalanceIndex = summedArrayClone.findIndex(
    (val) => val?.metricName === "Opening Bank Balance"
  );
  let closingBalanceIndex = summedArrayClone.findIndex(
    (val) => val?.metricName === "Closing Bank Balance"
  );
  let netCashIndex = summedArrayClone.findIndex(
    (val) => val?.metricName === "Net Cash Flows"
  );
  let dateKeysSorted = [];
  Object.keys(summedArrayClone[0]).forEach((key) => {
    if (
      !DEFAULT_KEYS.includes(key) &&
      !summedArrayClone?.[0]?.[key]?.["disabled"]
    ) {
      dateKeysSorted.push(key);
    }
  });
  dateKeysSorted = dateKeysSorted.sort((a, b) => moment(a).diff(moment(b)));
  dateKeysSorted.forEach((key, index) => {
    if (index !== 0) {
      if (!summedArrayClone?.[openingBalanceIndex]?.[key]?.["manualInput"])
        summedArrayClone[openingBalanceIndex][key]["value"] =
          summedArrayClone?.[closingBalanceIndex]?.[
            dateKeysSorted?.[index - 1]
          ]?.["value"] || 0;
    }
    summedArrayClone[closingBalanceIndex][key]["value"] =
      (summedArrayClone?.[openingBalanceIndex]?.[key]?.["value"] || 0) +
      (summedArrayClone?.[netCashIndex]?.[key]?.["value"] || 0);
  });

  return { cashClone: summedArrayClone, expenseClone };
};
