import { Formik } from "formik";
import React, { useRef, useState } from "react";
import "./xeroRegister.css";
import logo from "../../assets/formulr-logo.svg";
import {
  Button,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { ColorPalette } from "../../constants/colorPalette";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerXeroUser, xeroLogin } from "../../redux/User/UserSlice";
import Loading from "../../components/LoadingIndicator/Loading";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const XeroRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isFetching } = useSelector((state) => state.user);
  const [showRegistration, setShowRegistration] = useState(false);
  const { REACT_APP_BASE_API_URL } = process.env;
  const fileInputRef = useRef();
  const fileSize = 2097152;
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const steps = ["User Details", "Accounting Firm Details"];

  const [initialValues, setInitialValues] = useState({
    password: "",
    passwordConfirm: "",
    first_name: "",
    last_name: "",
    file: null,
    email: "",
    accFirmName: "",
  });

  const handleIconClick = (setFieldValue) => {
    if (fileInputRef.current) {
      setFieldValue("file", null);
      fileInputRef.current.click();
      return;
    }
  };

  const [loginEmail, setLoginEmail] = useState(null);

  const initiateXero = async () => {
    try {
      const response = await axios({
        method: "post",
        url: REACT_APP_BASE_API_URL + "/auth/xero-signup",
        data: {
          url: location.pathname + location.search,
          // email,
        },
      });
      const { payload } = response.data;
      if (payload && response.data.message === "Registration required") {
        setInitialValues((prevValues) => ({
          ...prevValues,
          first_name: payload.firstName || "",
          last_name: payload.lastName || "",
          email: payload.email || "",
        }));
        setShowRegistration(true);
        setIsLoading(false);
      } else if (payload && response.data.message === "User email exists") {
        setLoginEmail(payload?.email);
      }
    } catch (error) {
      console.error("Error fetching Xero Data:", error);
    }
  };
  useEffect(() => {
    initiateXero();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let loader = true;
    const handleLogin = async () => {
      if (loginEmail) {
        try {
          await dispatch(xeroLogin({ email: loginEmail }));

          if (loader) {
            navigate("/");
          }
        } catch (error) {
          console.log("Error logging in!");
        } finally {
          if (loader) {
            setIsLoading(false);
          }
        }
      }
    };
    handleLogin();
    return () => {
      loader = false;
    };

    // eslint-disable-next-line
  }, [loginEmail, dispatch, navigate]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (values, { setSubmitting, errors }) => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === steps.length - 1) {
      const formData = new FormData();
      formData.append("profilePic", values.file);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("password_confirm", values.passwordConfirm);
      formData.append("accFirmName", values.accFirmName);
      dispatch(registerXeroUser({ formData }));
    }
  };
  if (isLoading || isFetching || loginEmail) {
    return <Loading />;
  }

  if (!showRegistration) {
    return <Loading />;
  }
  return (
    <div className="register-wrapper">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors = {};
          if (values.password.length === 0) {
            errors.isPasswordEmpty = true;
            errors.password = "Required Field";
          }
          if (
            values.password.length !== 0 &&
            !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values.password)
          ) {
            errors.password =
              "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
          }
          if (!values.passwordConfirm) {
            errors.isPasswordConfirmEmpty = true;
            errors.passwordConfirm = "Required Field";
          }
          if (values.password && values.passwordConfirm) {
            if (values.password !== values.passwordConfirm) {
              errors.passwordConfirm = "Please enter the same password.";
            }
          }
          if (!values.first_name && activeStep === 1) {
            errors.first_name = "First name is required";
          }
          if (!values.last_name && activeStep === 1) {
            errors.last_name = "Last name is required";
          }
          if (!values.accFirmName && activeStep === 1) {
            errors.accFirmName = "Accounting Firm name is required";
          }

          if (values.file && activeStep === 1) {
            if (values.file.size >= fileSize) {
              errors.file = "Please upload an image smaller than 2MB";
            }
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setValues,
        }) => (
          <form
            style={{ width: "50%", height: "700px" }}
            className="form-wrapper-register"
            onSubmit={handleSubmit}
          >
            <Grid container justifyContent={"center"} className="mt-20 mb-20">
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={logo}
                alt="Logo"
              />
            </Grid>
            {activeStep === 0 ? (
              <Grid
                justifyContent="center"
                container
                spacing={2}
                sx={{ height: "400px", overflow: "auto" }}
              >
                <Grid item xs={9}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    disabled
                    error={!!errors.first_name && !!touched.first_name}
                    helperText={errors.first_name}
                    variant="outlined"
                    label="First name"
                    fullWidth
                    size="medium"
                    type="text"
                    name="first_name"
                    // onChange={handleChange}
                    value={values.first_name}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    disabled
                    error={!!errors.last_name && !!touched.last_name}
                    helperText={errors.last_name}
                    variant="outlined"
                    label="Last name"
                    fullWidth
                    size="medium"
                    type="text"
                    name="last_name"
                    // onChange={handleChange}
                    value={values.last_name}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    disabled
                    error={!!errors.email && !!touched.email}
                    helperText={errors.email}
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    value={values.email}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    error={!!errors.password && !!touched.password}
                    fullWidth
                    label="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <Typography
                    id="error-groupRequiredField"
                    variant="caption"
                    color={ColorPalette.danger}
                  >
                    {errors.password && !!touched.password && errors.password}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    error={
                      !!errors.passwordConfirm && !!touched.passwordConfirm
                    }
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    name="passwordConfirm"
                    onChange={handleChange}
                    value={values.passwordConfirm}
                  />
                  <Typography
                    id="error-groupRequiredField"
                    variant="caption"
                    color={ColorPalette.danger}
                    pl={1}
                  >
                    {errors.passwordConfirm &&
                      !!touched.passwordConfirm &&
                      errors.passwordConfirm}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid>
                <Grid
                  container
                  justifyContent="center"
                  spacing={2}
                  sx={{ height: "400px", overflow: "auto" }}
                >
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item mt={2} xs={9}>
                        <Typography variant="h6" fontWeight={"bold"}>
                          Accounting Firm Details
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          id="text-addGroupName"
                          fontSize={16}
                          className="mb-10"
                        >
                          Accounting Firm Name
                        </Typography>
                        <TextField
                          error={!!errors.accFirmName && !!touched.accFirmName}
                          fullWidth
                          name="accFirmName"
                          size="small"
                          onChange={handleChange}
                          value={values.accFirmName}
                        />
                        <Typography
                          id="error-groupRequiredField"
                          variant="caption"
                          color={ColorPalette.danger}
                          pl={1}
                        >
                          {errors.accFirmName &&
                            !!touched.accFirmName &&
                            errors.accFirmName}
                        </Typography>
                      </Grid>
                      <Grid mt={3} xs={9}>
                        <Typography
                          id="text-addGroupName"
                          fontSize={16}
                          className="mb-10 ml-10"
                        >
                          Accounting Firm Image
                        </Typography>
                        {values.file && !errors.file && (
                          <Grid>
                            <img
                              src={URL.createObjectURL(values.file)}
                              alt=""
                              width={80}
                              height={80}
                              style={{ borderRadius: "50%" }}
                            />
                          </Grid>
                        )}
                        <Grid item>
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              setFieldValue("file", e.currentTarget.files[0])
                            }
                          />
                          {values.file && !errors.file ? (
                            <Grid
                              sx={{
                                position: "relative",
                                bottom: 80,
                                left: 65,
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  setFieldValue("file", null);
                                  fileInputRef.current.value = null;
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          ) : (
                            <Button
                              onClick={() => handleIconClick(setFieldValue)}
                            >
                              <CameraAltIcon
                                sx={{
                                  color: "black",
                                  cursor: "pointer",
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              />
                            </Button>
                          )}
                        </Grid>
                        <Typography variant="body2" mb={1} ml={2}>
                          Upload Image(Not Mandatory)
                        </Typography>

                        {errors.file && (
                          <Typography sx={{ color: ColorPalette.danger }}>
                            {errors.file}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid>
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Stepper
                  sx={{
                    marginTop: 5,
                    width: "70%",
                  }}
                  activeStep={activeStep}
                  alternativeLabel
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel className="header-color">{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Grid
                  container
                  sx={{
                    justifyContent: "space-between",
                    mt: 3,
                    mb: 4,
                    px: 3,
                  }}
                >
                  <IconButton
                    id="button-stepperBack"
                    style={{ borderRadius: 0, fontSize: 16 }}
                    size="small"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon
                      style={{ marginRight: 10 }}
                      fontSize="20"
                    />
                    Back
                  </IconButton>
                  <IconButton
                    id="button-stepperFinishOrNext"
                    style={{ borderRadius: 0, fontSize: 16 }}
                    size="small"
                    variant="contained"
                    color="primary"
                    // onClick={
                    //   activeStep === steps.length - 1
                    //     ? handleSubmit
                    //     : handleNext
                    // }
                    onClick={handleSubmit}
                    type={activeStep === steps.length - 1 ? "submit" : "button"}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    <ArrowForwardIosIcon
                      style={{ marginLeft: 10 }}
                      fontSize="20"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default XeroRegister;
