import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, Download, Info, Upload } from "@mui/icons-material";
import { ColorPalette } from "../../../../../constants/colorPalette";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadEmployeeTemplate,
  resetDownloadTemplate,
} from "../../../../../redux/SpreadSheet/SpreadSheetSlice";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import moment from "moment/moment";
import { errorDescriptionTable } from "../../../../../helper/utils";

const EmployeeDataUpload = ({
  modalOpen,
  setModalOpen,
  bulkUploadError,
  setBulkUploadError,
  setBulkUploadList,
  setRefreshing,
  employeeData,
  isRefreshing,
  durationData,
}) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const { isDownloading, templateDownloadURL } = useSelector(
    (state) => state.spreadsheet
  );
  const [uploadFile, setUploadFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [importedMsg, setImportMsg] = useState(null);

  useEffect(() => {
    if (templateDownloadURL) {
      const link = document.createElement("a");
      link.href = templateDownloadURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(resetDownloadTemplate());
    }
  }, [templateDownloadURL, dispatch]);

  const mapObject = (key) => {
    const obj = {
      "Employee Name": "employeeName",
      "Pay Type": "paymentType",
      "Start Date": "startDate",
      "End Date (optional)": "endDate",
      "Hourly Rate $/hr": "hourlyRate",
      "Annual Salary": "annualSalary",
      "Weekly Hours": "weeklyHours",
      "Tax Free Threshold": "taxFreeThresholdApplied",
      STSL: "stslApplied",
    };
    return obj[key];
  };

  const paymentType = {
    hourly: "hourly",
    salary: "salary",
  };

  const handleChange = (e) => {
    const file = e.currentTarget.files[0];
    setFileName(file.name);
    if (file) {
      setUploadFile(file);
      setImportMsg("Please save to import the employee data to spreadsheet!");
    }
  };

  const hanldeIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveFile = () => {
    setUploadFile(null);
    setImportMsg("");
    setFileName(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const columnKeyMap = {
    employeeName: "Employee Name",
    paymentType: "Pay Type",
    startDate: "Start Date",
    endDate: "End Date",
    hourlyRate: "Hourly Rate",
    annualSalary: "Annual Salary",
    weeklyHours: "Weekly Hours",
    taxFreeThresholdApplied: "Tax Free Threshold Applied",
    stslApplied: "STSL Applied",
  };

  const validateRows = (arr) => {
    const errorsList = [];
    if (arr.length === 0) {
      errorsList.push({
        rowIndex: 0,
        general: "The file must include employee values to upload",
      });
      return errorsList;
    }

    const employeeNames = arr.map((row) => row.value.employeeName);
    const existingEmployeeNames = employeeData.map((val) =>
      val.employeeName.trim()
    );

    for (let index = 0; index < arr.length; index++) {
      for (const [key, value] of Object.entries(arr[index].value)) {
        switch (key) {
          case "employeeName":
            if (typeof value !== "string") {
              errorsList.push({
                rowIndex: index,
                key: "Employee Name",
                message: `The employee name must be string. Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            } else if (value.length < 4) {
              errorsList.push({
                rowIndex: index,
                key: "Employee Name",
                message: `The length of character must be more than 3 letters. Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            } else if (
              employeeNames.some(
                (name, i) => name.trim() === value.trim() && i !== index
              )
            ) {
              errorsList.push({
                rowIndex: index,
                key: "Employee Name",
                message: `The employee name ${value} which is in row ${
                  arr[index].index + 1
                } is repeated multiple times`,
              });
            } else if (existingEmployeeNames.includes(value.trim())) {
              errorsList.push({
                rowIndex: index,
                key: "Employee Name",
                message: `The employee name ${value} in row ${
                  arr[index].index + 1
                } already exists`,
              });
            }
            break;

          case "paymentType":
            if (!paymentType[value]) {
              errorsList.push({
                rowIndex: index,
                key: "Payment Type",
                message: `Please select the paymentType as hourly rate or salary. Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            }
            break;

          case "startDate":
            if (value.length !== 7) {
              errorsList.push({
                rowIndex: index,
                key: "Start Date",
                message: `Please select a valid date. Date format is YYYY-MM. Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            } else if (
              moment(value) >= moment(durationData.durationStart) &&
              moment(value) <= moment(durationData.durationEnd)
            ) {
              errorsList.push({
                rowIndex: index,
                key: "Start Date",
                message: `Please select the start date as value greater than duration start date and less than duration end date `,
              });
            }
            break;

          case "endDate":
            if (value?.length !== 7 && value !== null) {
              errorsList.push({
                rowIndex: index,
                key: "End Date",
                message: `Please select a valid date. Date format is YYYY-MM. Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            } else if (moment(value) < moment(arr[index].value.startDate)) {
              errorsList.push({
                rowIndex: index,
                key: "End Date",
                message: `End date cannot be before start date. Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            } else if (
              moment(value) >= moment(durationData.durationStart) &&
              moment(value) <= moment(durationData.durationEnd)
            ) {
              errorsList.push({
                rowIndex: index,
                key: "Start Date",
                message: `Please select end date as value greater than duration start date and less than duration end date `,
              });
            }
            break;

          case "hourlyRate":
            if (
              typeof value !== "number" &&
              arr[index].value.paymentType !== "salary"
            ) {
              errorsList.push({
                rowIndex: index,
                key: "Hourly Rate",
                message: `Hourly Rate must be a number. Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            }
            break;

          case "annualSalary":
            if (
              typeof value !== "number" &&
              arr[index].value.paymentType !== "hourly"
            ) {
              errorsList.push({
                rowIndex: index,
                key: "Annual Salary",
                message: `Annual salary must be a number. Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            }
            break;

          case "weeklyHours":
            if (typeof value !== "number") {
              errorsList.push({
                rowIndex: index,
                key: "Weekly Hours",
                message: `Weekly hours must be a number. Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            }
            break;

          case "taxFreeThresholdApplied":
            if (typeof value !== "boolean") {
              errorsList.push({
                rowIndex: index,
                key: "Tax Free Threshold Applied",
                message: `Please select the correct value (yes or no). Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            }
            break;

          case "stslApplied":
            if (typeof value !== "boolean") {
              errorsList.push({
                rowIndex: index,
                key: "STSL Applied",
                message: `Please select the correct value (yes or no). Error found in row ${
                  arr[index].index + 1
                } of ${columnKeyMap[key]} column`,
              });
            }
            break;

          default:
            break;
        }
      }
    }

    return errorsList;
  };

  const hanldeSubmit = async (e) => {
    const data = await uploadFile.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: null,
    });
    const headers = jsonData[0].slice(0, 9);
    const resultArray = [];

    for (let i = 1; i < jsonData.length; i++) {
      const rowValues = jsonData[i];
      const obj = headers.reduce((object, header, index) => {
        const mappedKey = mapObject(header);
        if (mappedKey) {
          let value = rowValues[index];
          if (mappedKey === "paymentType") {
            value = rowValues[index] === "Salary" ? "salary" : "hourly";
          }
          if (mappedKey === "startDate") {
            value = moment(value).format("YYYY/MM");
          } else if (mappedKey === "endDate") {
            value =
              value?.length === 7
                ? moment(value).format("YYYY/MM")
                : value === null
                ? null
                : "";
          } else if (value === "Yes") {
            value = true;
          } else if (value === "No") {
            value = false;
          }
          object[mappedKey] = value;
        }
        return object;
      }, {});
      if (
        obj.employeeName !== null &&
        obj.paymentType !== null &&
        obj.stslApplied !== null &&
        obj.taxFreeThresholdApplied !== null &&
        obj.startDate !== "Invalid Date"
      ) {
        resultArray.push({ value: obj, index: i });
      }
    }
    const isValid = validateRows(resultArray);

    const filteredRows = resultArray
      .filter((row) => {
        return !isValid.some((error) => error.rowIndex === row.index);
      })
      .map((row) => row.value);

    setBulkUploadList(filteredRows);
    setModalOpen(false);
    dispatch(setRefreshing());

    if (isValid.length) {
      // errorTableData(cellErrorMsg);
      setBulkUploadError((prevState) => ({
        ...prevState,
        modalOpen: true,
        error: isValid,
      }));
    }
  };

  const handleExport = () => {
    dispatch(downloadEmployeeTemplate({ accountingFirmId }));
  };

  return (
    <div>
      <CustomModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onClose={setModalOpen}
        maxWidth="md"
        overflow="auto"
      >
        <Grid container>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">Bulk Employee Data</Typography>
            <IconButton
              onClick={() => {
                setModalOpen(false);
                handleRemoveFile();
              }}
            >
              <Close />
            </IconButton>
            <Divider
              sx={{
                width: "100%",
                color: ColorPalette.danger,
                marginTop: "10px",
              }}
            />
          </Grid>
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={3}
          >
            <Grid item xs={5.5} mr={1}>
              <Grid>
                <Grid display={"flex"}>
                  <Typography variant="inherit" mb={2}>
                    Import Bulk Data
                  </Typography>
                  <Tooltip
                    className="ml-10"
                    placement="right"
                    title={
                      <p style={{ fontSize: "15px" }}>
                        {errorDescriptionTable()}
                      </p>
                    }
                  >
                    <Info />
                  </Tooltip>
                </Grid>
              </Grid>
              {fileInputRef && importedMsg ? (
                <Grid>
                  <Grid display="flex">
                    <Typography variant="">FileName:</Typography>
                    <Typography variant="body2">{fileName}</Typography>
                  </Grid>
                  <Button variant="outlined" onClick={handleRemoveFile}>
                    Remove File
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={3}>
                  <input
                    type="file"
                    accept=".xlsx"
                    ref={fileInputRef}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    endIcon={<Upload />}
                    variant="outlined"
                    onClick={hanldeIconClick}
                    sx={{ textTransform: "none" }}
                  >
                    Import
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={5.5}>
              <Typography variant="inherit" mb={2}>
                Download Employee Template
              </Typography>
              <LoadingButton
                loading={isDownloading}
                endIcon={<Download />}
                variant="contained"
                onClick={handleExport}
                sx={{ textTransform: "none" }}
              >
                Download
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={4}
            gap={3}
          >
            <Button
              onClick={(e) => hanldeSubmit(e)}
              variant="contained"
              disabled={!uploadFile}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setModalOpen(false);
                handleRemoveFile();
              }}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </div>
  );
};

export default EmployeeDataUpload;
