import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const XeroStatus = () => {
  return (
    <div>
      <Paper
        sx={{
          border: 0,
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          height: "90vh",
          padding: "20px",
        }}
      >
        <Grid container direction="column" sx={{ marginTop: 2 }}>
          <Grid item mb={7}>
            <Typography variant="h5" fontWeight="bold">
              Xero Status
            </Typography>
          </Grid>

          <Grid container item spacing={2} px={3}>
            <Grid item xs={6}>
              <Box
                sx={{
                  p: 3,
                  borderRadius: 2,
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                  backgroundColor: "#fff",
                }}
              >
                <Typography variant="subtitle1" fontWeight="medium">
                  Connected Tenant:
                </Typography>
                <Typography variant="body1">Some Value</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                sx={{
                  p: 3,
                  borderRadius: 2,
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                  backgroundColor: "#fff",
                }}
              >
                <Typography variant="subtitle1" fontWeight="medium">
                  Connected Status:
                </Typography>
                <Typography variant="body1">Some Value</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item container justifyContent="center">
            <Grid item xs={4} py={4}>
              <Button
                fullWidth
                variant="contained"
                color="error"
                sx={{
                  p: 2,
                  borderRadius: 3,
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                <Typography variant="button" fontWeight="bold">
                  Disconnect Xero
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default XeroStatus;
